import {Button, Container} from "./styles";
import React, {useEffect, useState} from "react";
import PainelDeDepositosService from "./service/painelDeDepositosService";
import LoadingOverlay from "../../../components/LoadingOverlay";
import {T2} from "../../../styles/titles";
import {DepositsTableData, TotalsData} from "./types";
import {Totals} from "./components/totals";
import {FormFilter} from "./components/filter";
import {LiveTable} from "./components/liveTable";

const PainelDeDepositos: React.FC = () => {
  const [filterData, setFilterData] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadMessage, setUploadMessage] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totals, setTotals] = useState<TotalsData | null>(null);
  const [tableData, setTableData] = useState<DepositsTableData | null>(null);
  const painelDeDepositosService = new PainelDeDepositosService();

  const fetchData = async () =>{
    setIsLoading(true);
    try {
      const data = await painelDeDepositosService.getDepositos(
        (currentPage - 1) * pageSize,
        pageSize,
        filterData);
      setTotals({
        totalValue: data.totalValue,
        totalRecords: data.totalRecords,
      });
      setTableData({
        records: data.records || [],
        count: data.count || 0,
      });
    }catch (error) {
      console.error("Erro ao buscar nos dados: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (filterData) {
      fetchData();
    }
  }, [filterData, currentPage, pageSize]);

  return (
    <Container>
      <LoadingOverlay
        show={isLoading}
        relative
      />
      <T2>Painel De Depósitos</T2>
      {totals && <Totals data={totals}/>}
      <FormFilter getFilters={(data => setFilterData(data))}/>
      {tableData && <LiveTable
        data={tableData}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={setCurrentPage}
        onPageSizeChange={setPageSize}
      />}
    </Container>
  )
}

export default PainelDeDepositos;
