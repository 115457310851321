import React, {useEffect, useState} from 'react';
import {
  CarOutlined,
  CarryOutOutlined,
  CreditCardOutlined,
  UserOutlined,
  FileSearchOutlined,
  IdcardOutlined,
  BarcodeOutlined,
  BarChartOutlined,
  QrcodeOutlined,
  UserAddOutlined,
  LinkOutlined,
  BorderlessTableOutlined
} from '@ant-design/icons';
import {
  AiOutlineFilePdf,
  AiOutlineProfile,
  AiFillCreditCard,
  AiFillWallet,
} from 'react-icons/ai'
import {
  MdAttachMoney,
  MdOutlineAccountBalanceWallet,
  MdOutlineCancelPresentation,
  MdOutlineEmojiTransportation,
  MdOutlineRequestPage,
} from 'react-icons/md';
import { BiCopy, BiTransfer, BiTransferAlt } from 'react-icons/bi';
import { GiReceiveMoney } from 'react-icons/gi';
import { FiUsers } from 'react-icons/fi';
import { BsArrowLeftRight, BsBoxArrowUp, BsCardList } from 'react-icons/bs';
import { GoNote } from 'react-icons/go';
import { VscLiveShare } from 'react-icons/vsc';
import { HiOutlineAdjustments } from 'react-icons/hi';
import { RiOrganizationChart} from 'react-icons/ri';
import { FaRegFilePowerpoint, FaHistory} from 'react-icons/fa';
import { MdOutlineCancel  } from "react-icons/md";


import {
  Sider,
  Logo,
  TecpayLogo,
  Menu,
  SubMenu,
  Item,
  Link,
} from '../styles';
import { ISideMenuProps } from '../types';
import { RoutesEnum } from '../../../routes/types';
import {FaMobileScreenButton} from "react-icons/fa6";
import {CiMoneyBill, CiShoppingCart, CiViewTable} from "react-icons/ci";
import {IConfigData} from "../../../store/config/types";
import logo from '../../../assets/tecpay-no-bg.png';

const SideMenu: React.FC<ISideMenuProps> = (props: ISideMenuProps) => {
  const { collapsed } = props;
  const [primaryColor, setPrimaryColor] = useState('');
  const [logoUrl, setLogoUrl] = useState('');

  useEffect(() => {
    const domain = window.location.hostname;
    const cachedConfig: IConfigData = JSON.parse(localStorage.getItem(`config_${domain}`) as string);
    if (cachedConfig) {
      setPrimaryColor(cachedConfig.corPrimaria);
      setLogoUrl(cachedConfig.linkImagem2);
    } else {
      setPrimaryColor('#0e2b27');
    }
  }, []);

  return (
    <Sider
      width={230}
      className="site-layout-background"
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{backgroundColor: primaryColor}}
    >
      <Logo to={RoutesEnum.DashboardComercial}>
        <TecpayLogo src={logoUrl || logo} />
      </Logo>
      <Menu
        mode="inline"
        theme="dark"
        style={{backgroundColor: primaryColor}}
      >
        <SubMenu key="admin" title="Administrador" icon={<UserOutlined />}>
          <Item key="admin-1" icon={<FiUsers />}>
            <Link to="/tecpay/admin/usuarios">
              Usuários
            </Link>
          </Item>
          {/*<Item key="admin-2" icon={<BsArrowLeftRight />}>
            <Link to="/tecpay/admin/transacoes-nossa-cidade">
              Transações Nossa Cidade
            </Link>
          </Item>
          <SubMenu key="admin-payments" title="Cartão de crédito/débito" icon={<CreditCardOutlined />}>
            <Item key="admin-payments-1">
              <Link to="/tecpay/admin/cancelamentos">
                Cancelamentos
              </Link>
            </Item>
            <Item key="admin-payments-2">
              <Link to="/tecpay/admin/chargebacks">
                Chargebacks
              </Link>
            </Item>
          </SubMenu>
          <Item key="3" icon={<MdAttachMoney />}>
            <Link to="/tecpay/admin/vendas">
              Vendas
            </Link>
          </Item>
          <Item key="4" icon={<GiReceiveMoney />}>
            <Link to="/tecpay/admin/adiantamentos">
              Adiantamentos
            </Link>
          </Item>
          <Item key="5" icon={<GoNote />}>
            <Link to="/tecpay/admin/notaPromissoria">
              Notas Promissorias
            </Link>
          </Item>
          <Item icon={<FileSearchOutlined />} key="credenciais">
            <Link to="/tecpay/admin/credenciais/criar-credencial">
              Credenciais
            </Link>
          </Item> */}
          <Item icon={<IdcardOutlined />} key="business">
            <Link to="/tecpay/admin/empresas">
              Empresas
            </Link>
          </Item>
          <Item icon={<LinkOutlined />} key="link-business">
            <Link to="/tecpay/admin/vincular-empresa">
              Vincular Empresa
            </Link>
          </Item>

          {/*<SubMenu key="cadastro-geral" title="Cadastro Geral" icon={<FaBullseye />}>*/}
          {/*</SubMenu>*/}
          {/*<Item icon={<AiFillWallet />} key="wallet">*/}
          {/*  <Link to="/tecpay/admin/carteira-virtual">*/}
          {/*    Carteira Virtual*/}
          {/*  </Link>*/}
          {/*</Item>*/}
          {/*<Item icon={<UserAddOutlined />} key="conta-digital-2-proxy">*/}
          {/*  <Link to="/conta-digital/proxy">*/}
          {/*    Proxy*/}
          {/*  </Link>*/}
          {/*</Item>*/}
        </SubMenu>
        {/* <SubMenu key="conta-digital" title="Conta Digital" icon={<MdOutlineAccountBalanceWallet />}>
          <Item icon={<FileSearchOutlined />} key="conta-digital-1">
            <Link to="/conta-digital/extrato">
              Extrato
            </Link>
          </Item>
          <Item icon={<FaRegFilePowerpoint />} key="conta-digital-2">
            <Link to="/contas-digitais/politicas-cobrancas">
              Políticas de cobranças
            </Link>
          </Item>
        </SubMenu> */}
        {/*  <SubMenu key="boletos" title="Boleto" icon={<BarcodeOutlined />}>
          <Item key="boletos-1" icon={<MdOutlineRequestPage />}>
            <Link to="/boletos/pagamentos">
              Pagamentos
            </Link>
          </Item>
          <Item key="boletos-2" icon={<AiOutlineProfile />}>
            <Link to="/boletos">
              Boletos
            </Link>
          </Item>
          <Item key="boletos-3" icon={<AiOutlineFilePdf />}>
            <Link to="/boletos/gerar-boleto/cnab">
              Gerar Boleto CNAB
            </Link>
          </Item>
        </SubMenu>
        <SubMenu key="cartao" title="Cartão" icon={<AiFillCreditCard />}>
          <Item icon={<FaRegCreditCard />}>
            <Link to="/conta-digital/carga-cartao">
              Recarregar Cartão
            </Link>
          </Item>
          <Item icon={<FileSearchOutlined />}>
            <Link to="/conta-digital/extrato-cartao">
              Extrato Cartão
            </Link>
          </Item>
        </SubMenu>
        <SubMenu key="pix" title="Pix" icon={<QrcodeOutlined />}>
          <Item key="pix-1" icon={<BiCopy />}>
            <Link to="/pix/copia-e-cola">
              Recebimento
            </Link>
          </Item>
          <Item key="pix-2" icon={<BsBoxArrowUp />}>
            <Link to="/pix/pagamentos">
              Pagamentos
            </Link>
          </Item>
        </SubMenu> */}
        <SubMenu key="repasses" title="Transações e Repasses" icon={<BiTransfer />}>
          {/* <Item icon={<BsCardList />} key="detail">
            <Link to="/tecpay/admin/transacoes-repasse">
              Detalhes
            </Link>
          </Item>
          <Item icon={<VscLiveShare />} key="live">
            <Link to="/tecpay/admin/transacoes-tempo-real">
              Transações em Tempo Real
            </Link>
          </Item>
          <Item icon={<HiOutlineAdjustments />} key="adjustments">
            <Link to="/tecpay/admin/ajustes">
              Ajustes
            </Link>
          </Item>
          <Item icon={<RiFolderTransferLine />} key="repasses">
            <Link to="/tecpay/admin/repasses">
              Repasses
            </Link>
          </Item>
          <Item icon={<BiTransferAlt />} key="transferenciaRepasses">
            <Link to="/tecpay/admin/repasses/transferencias">
              Transferencias de Repasses
            </Link>
          </Item> */}
          <Item icon={<VscLiveShare />} key="painelVendas">
            <Link to="/tecpay/admin/painel-de-vendas">
              Vendas
            </Link>
          </Item>
          <Item icon={<VscLiveShare />} key="painelRecebiveis">
            <Link to="/tecpay/admin/painel-de-recebiveis">
              Recebíveis
            </Link>
          </Item>
          <Item icon={<VscLiveShare />} key="painelDePagamentos">
            <Link to="/tecpay/admin/painel-de-pagamentos">
              Pagamentos
            </Link>
          </Item>
          <Item icon={<VscLiveShare />} key="painelDeDepositos">
            <Link to="/tecpay/admin/painel-de-depositos">
              Depósitos
            </Link>
          </Item>
        </SubMenu>
        <SubMenu key="cancelamento" title="Painel de Cancelamentos" icon={<MdOutlineCancelPresentation />}>
          <Item icon={<MdOutlineCancel />} key="painelDeCancelamentos">
            <Link to="/tecpay/admin/login-cancelamento">
              Cancelamento de Venda
            </Link>
          </Item>
          <Item icon={<FaHistory />} key="historicoDeCancelamentos">
            <Link to="/tecpay/admin/historico-de-cancelamentos">
              Histórico de Cancelamentos
            </Link>
          </Item>

        </SubMenu>
        <SubMenu key="adiqEdi" title="EDI Adiq" icon={<CiMoneyBill />}>
          <Item icon={<CiViewTable />} key="detalhesEdi">
            <Link to="/tecpay/admin/detalhes-edi">
              Detalhes
            </Link>
          </Item>
          <Item icon={<RiOrganizationChart />} key="gerenciadorEdi">
            <Link to="/tecpay/admin/gerenciador-edi">
              Gerenciador de Arquivos
            </Link>
          </Item>
        </SubMenu>
        {/* <SubMenu key="veiculos" title="Veículos" icon={<CarOutlined />}>
          <Item key="veiculos-1" icon={<MdOutlineEmojiTransportation />}>
            <Link to="/veiculos">
              Meus Veículos
            </Link>
          </Item>
          <SubMenu key="veiculos-debitos-veiculares" title="Débitos Veiculares" icon={<CarryOutOutlined />}>
            <Item key="veiculos-debitos-veiculares-1">
              <Link to="/debitos-veiculares/pagamentos">
                Meus Pagamentos
              </Link>
            </Item>
            <Item key="veiculos-debitos-veiculares-2">
              <Link to="/debitos-veiculares/debitos">
                Débitos
              </Link>
            </Item>
            <Item key="veiculos-debitos-veiculares-3">
              <Link to="/debitos-veiculares/multas-renainf">
                Multas Renainf
              </Link>
            </Item>
          </SubMenu>
        </SubMenu> */}
        <Item icon={<FaRegFilePowerpoint />} key="cadastro-geral-1">
          <Link to="/tecpay/admin/cadastro-geral/politicas-cobranca">
            Políticas de Cobrança
          </Link>
        </Item>
        <SubMenu key="simulador" title="Simulador" icon={<BorderlessTableOutlined />}>
          <Item  key="simulador-recebimento">
            <Link to="/simulador/recebimento">
              Quero receber
            </Link>
          </Item>
          <Item key="simulador-venda">
            <Link to="/simulador/venda">
              Quero vender por
            </Link>
          </Item>
        </SubMenu>
        <Item icon={<FaMobileScreenButton />} key="terminaisPhoebus">
          <Link to="/terminaisPhoebus">
            Terminais
          </Link>
        </Item>
        {/*<Item icon={<FaMobileScreenButton />} key="terminais">*/}
        {/*  <Link to="/terminais">*/}
        {/*    Terminais*/}
        {/*  </Link>*/}
        {/*</Item>*/}
        <Item icon={<BarChartOutlined />} key="dashboard-comercial">
          <Link to="/comercial/dashboard">
            Dashboard Comercial
          </Link>
        </Item>
        <Item icon={<CiShoppingCart />} key="cadastro">
          <a href="https://app.pipefy.com/public/form/YR58XO5r" target="_blank">
            Cadastro de cliente
          </a>
        </Item>
      </Menu>
    </Sider>
  );
};
export default SideMenu;
