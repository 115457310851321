import {FormValues} from "../types";
import {getAxiosInstance} from "../../../services/providers/api";

export default class TerminaisPhoebusService {

  getMetaData() {
    const userInfo: any = localStorage.getItem('userInfo');
    let parsedUserInfo = null;
    if (userInfo) parsedUserInfo = JSON.parse(userInfo);

    let commercialHierId = null;
    if (parsedUserInfo.commercialHierId) commercialHierId = parsedUserInfo.commercialHierId;

    const selectedCompany: string | null = localStorage.getItem('selectedCompany');
    let parsedSelectedCompany = null;
    if (selectedCompany) parsedSelectedCompany = JSON.parse(selectedCompany);

    return {
      commercialHierId: commercialHierId,
      selectedCompany: parsedSelectedCompany
    }
  }

  getAllTerminals = async (
    page: number,
    pageSize: number,
    filter?: FormValues,
    merchant?: string,
    user?: any
  ) => {

    const { commercialHierId, selectedCompany } = this.getMetaData();

    const paged = true;
    const terminal = filter?.terminal !== undefined && filter?.terminal.length > 0 ? filter?.terminal : null;

    let merchantId = selectedCompany ? selectedCompany.merchantId : filter?.merchantId !== undefined && filter?.merchantId.length > 0 ? filter?.merchantId : null;
    let status = filter?.status !== -1 ? filter?.status : null;

    if (merchant) {
      merchantId = merchant;
      status = 1;
    }

    if (user.userType !== 3) {
      status = 1;
    }

    const serialNumber = filter?.serialNumber !== undefined && filter?.serialNumber.length > 0 ? filter?.serialNumber : null;
    const modelName = filter?.modelName !== -1 ? filter?.modelName : null;
    const tefTerminal = filter?.tefTerminal !== undefined && filter?.tefTerminal.length > 0 ? filter?.tefTerminal : null;
    const isAssociated = filter?.isAssociated !== -1 ? filter?.isAssociated : null;

    try {
      const response = await getAxiosInstance().get('/terminal/get-terminals', {
        params: {
          offSet: page,
          pageSize: pageSize,
          paged: paged,
          terminal: terminal,
          merchantId: merchantId,
          status: status,
          serialNumber: serialNumber,
          modelName: modelName,
          tefTerminal: tefTerminal,
          isAssociated: isAssociated,
        }
      });

      return response.data.response;
    } catch (error) {
      return {
        success: false,
        error: error
      }
    }
  }

  getTerminalDownload = async (
    page: number,
    pageSize: number,
    filter?: FormValues,
    user?: any
  ) => {
    const { commercialHierId, selectedCompany } = this.getMetaData();

    const terminal = filter?.terminal !== undefined && filter?.terminal.length > 0 ? filter?.terminal : null;
    const merchantId = selectedCompany ? selectedCompany.merchantId : filter?.merchantId !== undefined && filter?.merchantId.length > 0 ? filter?.merchantId : null;
    let status = filter?.status !== -1 ? filter?.status : null;

    if (user.userType !== 3) {
      status = 1;
    }

    const serialNumber = filter?.serialNumber !== undefined && filter?.serialNumber.length > 0 ? filter?.serialNumber : null;
    const modelName = filter?.modelName !== -1 ? filter?.modelName : null;
    const tefTerminal = filter?.tefTerminal !== undefined && filter?.tefTerminal.length > 0 ? filter?.tefTerminal : null;
    const isAssociated = filter?.isAssociated !== -1 ? filter?.isAssociated : null;

    try {
      const response = await getAxiosInstance().get('/terminal/get-terminals-xls', {
        params: {
          offSet: page,
          pageSize: pageSize,
          paged: false,
          terminal: terminal,
          merchantId: merchantId,
          status: status,
          serialNumber: serialNumber,
          modelName: modelName,
          tefTerminal: tefTerminal,
          isAssociated: isAssociated,
        },
          responseType: 'blob'
      });

      return response.data;
    } catch (error) {
      return {
        success: false,
        error: error
      }
    }
  }

  forceTerminalJob = async ()=> {
    const page = 1;
    const pageSize = 100;

    return getAxiosInstance().get('/phoebus/terminal', {
      params: {
        page: page,
        pageSize: pageSize
      }
    })
  }
}
