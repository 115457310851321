function getAcquirerNames (acquirerCode: number): string {
  const acquirerMap: { [key: number]: string } = {
    1: 'Stone',
    2: 'EntrePay',
    3: 'Adiq',
    4: 'Cielo',
    5: 'Conductor',
    6: 'Banese',
    7: 'Senff',
    8: 'PagSeguro',
    9: 'Acqio',
    10: 'Rede',
    11: 'Crefisa',
    12: 'Banese TKS',
    13: 'Prisma',
    14: 'America Express',
    15: 'SafraPay',
    16: 'Shift4',
    17: 'Getnet',
    18: 'Rendimento Pay',
    39: 'Wipay',
    40: 'Pecunpay',
  };

  return acquirerMap[acquirerCode] || '';
}

export function formatAcquirer (primaryTefAcquirer: number,
                               primaryPrioty: number,
                               secondaryTefAcquirer: number,
                               secondaryPrioty: number) : string {
  const selectedAcquirer: number | null =
    primaryPrioty === 1 ? primaryTefAcquirer
      : secondaryPrioty === 1 ? secondaryTefAcquirer
        : null;

  return selectedAcquirer !== null ? getAcquirerNames(selectedAcquirer) : '';

}

export function formatDate(dateString?: string): string {
  if (!dateString) {
    return ''; // Retorna vazio se a string estiver indefinida ou vazia
  }

  // Converte a string de data para um objeto Date
  const date = new Date(dateString);

  // Verifica se a data é válida
  if (isNaN(date.getTime())) {
    return ''; // Retorna vazio se a data for inválida
  }

  // Extrai os componentes da data
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês é baseado em 0
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Formata a data como DD/MM/AAAA - HH:MM:SS
  return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;
}

export const formatModel = (value: string): string => {
  if (value === "L3") {
    value = "L300";
  }

  return value;
}
