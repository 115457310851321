import styled from "styled-components";
import {Button2} from "../../../../../styles/buttons";
import { Col as AntdCol } from 'antd';

export const Form = styled.form``;

export const Label = styled.label`
  font-size: 18px;
  font-weight: bold;
`;

export const Col = styled(AntdCol)`
  width: 12.4rem;
  margin-bottom: 0.8rem;

  input {
    width: 100%;
    height: 2rem;
    padding: 0 11px                                                                           ;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;

    &:focus, &:hover {
      border-color: ${(props) => props.theme.colors.cyanDark};
      outline-color: transparent;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgb(22 158 173 / 20%);
    }
  }

  @media (max-width: 768px) {
    min-width: 0em;
  }
`;


export const FilterContainer = styled.div`
  display: flex;
  width: 99%;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const FilterRow = styled.div`
  display: flex;

  @media (max-width: 768px) {
    width: 99%;
    justify-content: space-between;
  }
`;

export const Button = styled(Button2)`
  margin-top: -1px;
  border-radius: 20px;
`


