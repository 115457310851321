import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  isContratoModalOpen: false,
  contratos: {
    count: 0,
    offset: 0,
    records: [],
    pageSize: 20,
  },
  contrato: {
    clienteTravado: {
      id: '',
      name: '',
      lastName: '',
      email: '',
      documentNumber: '',
      phoneNumber: '',
      createdAt: new Date(),
      birthDate: new Date(),
      userType: 1,
      travaAntecipadora: false,
      commercialHierId: 1,
    },
    diaDebitoMensal: 0,
    id: '',
    tipoContrato: 0,
    valorContrato: '',
    contratoAtivo: false,
    valorLiquidado: '',
    valorParaDebito: '',
  },
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.GET_ALL_CONTRATOS:
      return {
        ...state,
        loading: false,
        contratos: action.payload?.contratos ?? { ...InitialState.contratos },
      };
    case ActionTypes.CREATE_NEW_CONTRATO:
      return {
        ...state,
        loading: false,
        isContratoModalOpen: false,
      }
    case ActionTypes.OPEN_CONTRATO_MODAL:
      return {
        ...state,
        loading: false,
        isContratoModalOpen: action.payload.status ?? false,
      }
    default:
      return { ...state };
  }
};
