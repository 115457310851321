import {TotalsData} from "../../types";
import {formatMoney} from "../../utils";
import {ValueCard} from "./styles";

export const Totals: React.FC<{ data: TotalsData}> = ({ data }) => {

  const formatTotal = (value: number): string => {
    if (isNaN(value)) value = 0;
    return formatMoney(value);
  }

  return (
    <>
      <ValueCard>
        <div>
          <p style={{fontWeight: 'bolder'}}>Valor Depositado:</p>
          <p>{formatTotal(data.totalValue)}</p>
        </div>
      </ValueCard>
    </>
  )
}
