import {getAxiosInstance} from "../../../../services/providers/api";
import {PainelVendasFilterProps} from "../../../../global/dataTransferObjects/transacoesTempoReal";
import axios from "axios";
import config from "../../../../config";

export default class PainelDePagamentosService {

  async downloadPagamentos (
    type: string,
    filter: any
  ) {
    const token: any = localStorage.getItem('accessToken');
    let parsedToken = null;
    if (token) parsedToken = JSON.parse(token);

    const formattedFilter = {
      Acquirer: filter.acquirer,
      AnticipationIncluded: filter.anticipationIncluded,
      BusinessName: filter.businessName,
      Paid: filter.paid,
      SaleInitDate: filter.initialDate,
      SaleFinishDate: filter.finalDate,
      TipoPagamento: filter.tipoPagamento,
      Valori: true
    };

    if (formattedFilter.Acquirer == -1) delete formattedFilter.Acquirer;

    const result = await axios.post(`${config.wlBaseurl}/painel-pagamentos/download`, {
        ...formattedFilter
      },
      {
        headers: {
          Authorization: `Bearer ${parsedToken.token}`,
        },
        responseType: 'blob'
      })

    try {
      const objectUrl = window.URL.createObjectURL(result.data);
      const a = document.createElement('a');
      a.href = objectUrl;

      if (type === 'xls') a.download = 'PainelDePagamentos.xlsx';
      else  a.download = 'PainelDePagamentos.csv';

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(objectUrl);
      document.body.removeChild(a);

    } catch (error) {
      console.error('Erro ao exportar tabela:', error);
    }
  }

  async getPagamentos (filter: any) {
    const token: any = localStorage.getItem('accessToken');
    let parsedToken = null;
    if (token) parsedToken = JSON.parse(token);

    const formattedFilter = {
      Acquirer: filter.acquirer,
      AnticipationIncluded: filter.anticipationIncluded,
      BusinessName: filter.businessName,
      Paid: filter.paid,
      SaleInitDate: filter.initialDate,
      SaleFinishDate: filter.finalDate,
      TipoPagamento: filter.tipoPagamento,
      Valori: true
    };

    if (formattedFilter.Acquirer == -1) delete formattedFilter.Acquirer;

    return axios.post(`${config.wlBaseurl}/painel-pagamentos/filtro`, {
      ...formattedFilter
    },
      {
        headers: {
          Authorization: `Bearer ${parsedToken.token}`,
        },
      })
  }

  async updatePagamentosExternos (filter: any, data: any) {
    const token: any = localStorage.getItem('accessToken');
    let parsedToken = null;
    if (token) parsedToken = JSON.parse(token);

    return await axios.post(`${config.wlBaseurl}/painel-pagamentos/atualiza`, {
        SaleInitDate: filter.initialDate,
        SaleFinishDate: filter.finalDate,
        TipoPagamento: filter.tipoPagamento,
        BusinessName: filter.businessName,
        Acquirer: filter.acquirer == -1  ? null : filter.acquirer,
        Valori: true,
        MerchantStatus: data
      },
      {
        headers: {
          Authorization: `Bearer ${parsedToken.token}`,
        },
      })
  }

  async updatePagamentosCredito (data: any) {
    const token: any = localStorage.getItem('accessToken');
    let parsedToken = null;
    if (token) parsedToken = JSON.parse(token);

    return axios.post(`${config.wlBaseurl}/painel-pagamentos/atualiza-credito`, data,
      {
        headers: {
          Authorization: `Bearer ${parsedToken.token}`,
        },
      })
  }

  async updatePagamentosDebitoPix (data: any) {
    const token: any = localStorage.getItem('accessToken');
    let parsedToken = null;
    if (token) parsedToken = JSON.parse(token);

    return axios.post(`${config.wlBaseurl}/api/painel-pagamentos/atualiza-debito`,data,
      {
        headers: {
          Authorization: `Bearer ${parsedToken.token}`,
        },
      })
  }

  async updatePagamentosPixAsync (data: any) {
    const token: any = localStorage.getItem('accessToken');
    let parsedToken = null;
    if (token) parsedToken = JSON.parse(token);

    return axios.post(`${config.wlBaseurl}/painel-pagamentos/atualiza-pix`, data,
      {
        headers: {
          Authorization: `Bearer ${parsedToken.token}`,
        },
      })
  }

}
