export const formatMoney = (value: number): string => {
  const formattedValue: string = (value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formattedValue;
};

export const formatAgency = (agency: string): string => {
  if (agency.length >= 4)
    return agency.slice(-4);
  else
    return agency;
}
