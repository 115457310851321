import React, {ChangeEvent, ChangeEventHandler, useEffect, useState} from "react";
import {Container} from "./styles";
import {T2} from "../../../styles/titles";
import {FormValues} from "./types";
import {toast} from "react-toastify";
import FormInput from "../../../components/Forms/Input";
import {Form, Formik} from 'formik';
import {Col, Row} from "antd";
import {Button} from "../../business/newBusiness/components/Form/style"
import {formatCurrency, formatErrorMessage, parseCurrency} from "./utils";
import LoadingOverlay from "../../../components/LoadingOverlay";
import CancelamentoService from "./services/CancelamentoService";
import {getErrorMessage} from "../../../utils/getErrorMessage";


const PainelDeCancelamentos: React.FC = () => {
  const cancelamentoService = new CancelamentoService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transaction_value, setTransactionValue] = useState("");
  const [cancelValue, setCancelValue] = useState("");
  const [merchant_id, setMerchantId] = useState("");
  const [authorization_code, setAuthorizationCode] = useState("");
  const [nsu, setNsu] = useState("");
  const [codigo_lojista, setCodigoLojista] = useState("");
  const [acquirer, setAcquirer] = useState('adiq');

  const handleTransactionValue = (e: any) => {
    const rawValue = e.target.value.replace(/\D/g, '');
    const formattedValue = formatCurrency(rawValue);
    setTransactionValue(formattedValue);
  }

  const handleCancelValue = (e: any) => {
    const rawValue = e.target.value.replace(/\D/g, '');
    const formattedValue = formatCurrency(rawValue);
    setCancelValue(formattedValue);
  }

  const handleMechantId = (e: any) => {
    const rawValue = e.target.value.replace(/\D/g, '').slice(0, 15);
    setMerchantId(rawValue);
  }

  const handleNsu = (e: any) => {
    const rawValue = e.target.value.replace(/\D/g, '').slice(0, 12);
    setNsu(rawValue);
  }

  const handleAuthorizationCode = (e: any) => {
    const rawValue = e.target.value.slice(0, 6);
    setAuthorizationCode(rawValue);
  }

  const handleCodLojista = (e: any) => {
    const rawValue = e.target.value.replace(/\D/g, '').slice(0, 6);
    setCodigoLojista(rawValue);
  }

  const cancelarTransacao = async (submit: any) => {
    setIsLoading(true);
    const date = new Date(submit.transaction_date);
    const formattedDate = `${String(date.getUTCDate()).padStart(2, '0')}/${String(date.getUTCMonth() + 1).padStart(2, '0')}/${date.getUTCFullYear()}`;
    submit.transaction_date = formattedDate;

    try {
      const response = await cancelamentoService.cancelTransactions(submit);
      await handleCancelResponse(response);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelResponse = async (response: any) => {
      const {
        success,
        message
      } = response.response;

      if (success) {
        toast.success(message);
      } else {
        const errorMessage = formatErrorMessage(response.data);
        toast.error(errorMessage)
      }
  };

  const handleAcquirer = (e: ChangeEvent<HTMLInputElement>) => {
    setAcquirer(e.target.value);
  };

  return (
    <Container>
      <LoadingOverlay
        show={isLoading}
        relative
      />

      <T2>Cancelar Transações ADIQ</T2>

      <Formik<FormValues>
        initialValues={{
          merchant_id: "",
          transaction_date: "",
          nsu: "",
          authorization_code: "",
          value_tx_ori: "",
          cancel_amount: "",
          cancel_observations: "",
          codigo_lojista: "",
          acquirer: "adiq"
        }}
        onSubmit={async (values: any) => {

          const value_tx_oriNumber = parseCurrency(values.value_tx_ori);
          const cancel_amountNumber = parseCurrency(values.cancel_amount);

          if (values.merchant_id.length < 15 && acquirer === 'adiq') {
            toast.error('Código do Lojista Inválido')
            return;
          } else if (values.transaction_date === "") {
            toast.error("Data da transação Inválida!");
            return;
          } else if (values.authorization_code.length < 6) {
            toast.error('Código de autorização Inválido!')
            return
          } else if (values.value_tx_ori === "") {
            toast.error('Valor total da transação é obrigatório!');
            return;
          } else if (values.cancel_amount === "") {
            toast.error('Valor a ser cancelado é obrigatório!');
            return;
          } else if (values.cancel_observations === "") {
            toast.error('Texto de observação da transação é obrigatório');
            return;
          } else if (values.codigo_lojista.length < 6) {
            toast.error('Código do lojista é obrigatório');
            return;
          } else if (cancel_amountNumber > value_tx_oriNumber) {
            toast.error('O valor a ser cancelado não pode ser maior que o valor da transação');
            return;
          } else {
            cancelarTransacao(values);
            values.transaction_date = "";
          }
        }}
      >
        {({setFieldValue, values}) => {
          useEffect(() => {
            setFieldValue('value_tx_ori', transaction_value);
          }, [transaction_value]);

          useEffect(() => {
            setFieldValue('cancel_amount', cancelValue);
          }, [cancelValue]);

          useEffect(() => {
            setFieldValue('merchant_id', merchant_id);
          }, [merchant_id]);

          useEffect(() => {
            setFieldValue('nsu', nsu);
          }, [nsu]);

          useEffect(() => {
            setFieldValue('authorization_code', authorization_code);
          }, [authorization_code]);

          useEffect(() => {
            setFieldValue('codigo_lojista', codigo_lojista);
          }, [codigo_lojista]);

          useEffect(() => {
            setFieldValue('acquirer', acquirer);
          }, [acquirer]);

          return (
            <Form>
              <Row gutter={18} align="top" justify="start">
                <Col span={2}>
                  <label>Adiq
                    <input name="acquirers" type="radio" value='adiq'
                           checked={acquirer === 'adiq'} onChange={handleAcquirer} />
                  </label>
                </Col>
                <Col span={2}>
                  <label>Rede
                    <input name="acquirers" type="radio" value='rede'
                           checked={acquirer === 'rede'} onChange={handleAcquirer} />
                  </label>
                </Col>
              </Row>
              <br/>
              <Row gutter={18} align="top" justify="start">
                {acquirer === 'adiq' && (
                  <Col span={12}>
                    <FormInput
                      type="text"
                      name="merchant_id"
                      label="Código do Lojista (Adquirente)"
                      required
                      placeholder="017001573028063"
                      value={merchant_id}
                      onChange={handleMechantId}
                    />
                  </Col>
                )}
                <Col span={12}>
                  <FormInput
                    type="date"
                    name="transaction_date"
                    label="Data da transação"
                    required
                  />
                </Col>
                <Col span={12}>
                  <FormInput
                    type="text"
                    name="nsu"
                    label="NSU Adquirente"
                    required
                    placeholder="000052853071"
                    value={nsu}
                    onChange={handleNsu}
                  />
                </Col>
                <Col span={12}>
                  <FormInput
                    type="text"
                    name="authorization_code"
                    label="Autorização"
                    required
                    placeholder="171155"
                    value={authorization_code}
                    onChange={handleAuthorizationCode}
                  />
                </Col>
                <Col span={12}>
                  <FormInput
                    type="text"
                    name="value_tx_ori"
                    label="Valor total da transação"
                    required
                    placeholder="1.258,65"
                    prefix="R$"
                    value={transaction_value}
                    onChange={handleTransactionValue}
                  />
                </Col>
                <Col span={12}>
                  <FormInput
                    type="text"
                    name="cancel_amount"
                    label="Valor a ser cancelado da transação"
                    required
                    placeholder="550,89"
                    prefix="R$"
                    value={cancelValue}
                    onChange={handleCancelValue}
                  />
                </Col>
                <Col span={12}>
                  <FormInput
                    type="text"
                    name="cancel_observations"
                    label="Observações de cancelamento"
                    required
                    placeholder="Observação do cancelamento"
                  />
                </Col>
                <Col span={12}>
                  <FormInput
                    type="text"
                    name="codigo_lojista"
                    label="Código do lojista (Paystore)"
                    required
                    placeholder="414243"
                    value={codigo_lojista}
                    onChange={handleCodLojista}
                  />
                </Col>
              </Row>
              <Row justify="end">
                <Button htmlType="submit">
                  Cancelar Transação
                </Button>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default PainelDeCancelamentos;
