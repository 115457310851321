import { IFilter } from "../../types";
import React, {useEffect} from "react";
import { Container } from "../../styles";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import { Row } from "antd";
import { Field, Formik, Form } from "formik";
import {FilterContainer, FilterRow, Label, Button, Col} from "./styles";
import Select from "antd/lib/select";
import useGetMe from "../../../../hooks/useCurrentUser";

export const FormFilter: React.FC<IFilter> = (props: IFilter) => {
  const { getFilters } = props;
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const user = useGetMe();

  const initialFormValues = {
    terminal: '',
    merchantId: '',
    status: -1,
    serialNumber: '',
    modelName: -1,
    tefTerminal: '',
    isAssociated: -1
  };

  const filterTerminalsPhoebus = async (submit: any) => {
    try {
      if (user.userType == 1 || user.userType == 2)
      {
        submit.isAssociated = 1;
      }
      getFilters(submit); // Envia os dados para o componente pai
    } catch (error) {
      console.error("Erro ao enviar os filtros:", error);
    }
    return submit;
  };

  useEffect(() => {
    filterTerminalsPhoebus(initialFormValues);
  }, [])

  return (
    <Container style={{ padding: "0px" }}>
      <LoadingOverlay
        show={isLoading}
        relative
      />

      <Row>
        <Label>Filtrar por:</Label>
      </Row>

      <Formik
        initialValues={initialFormValues}
        onSubmit={ async (values: any) => {
          await filterTerminalsPhoebus(values); // Envia os filtros ao clicar no botão "Consultar"
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Row gutter={16}>
              <Col>
                <Field
                  type="text"
                  name="terminal"
                  placeholder="Num. Lógico do Terminal"
                />
              </Col>

              {(user.userType === 3 || user.userType === 6) && (
                <Col>
                  <Field
                    type="text"
                    name="merchantId"
                    placeholder="Cód. Lojista"
                  />
                </Col>
              )}

              <Col>
                <Field
                  type="text"
                  name="serialNumber"
                  placeholder="Serial number"
                />
              </Col>

              <Col>
                <Field
                  type="text"
                  name="tefTerminal"
                  placeholder="TID"
                />
              </Col>

              {(user.userType === 3 || user.userType === 6 || user.userType === 7) && (
                <>
                  <Col>
                    <Select
                      value={values.isAssociated}
                      style={{ width: "100%", marginRight: "10px" }}
                      onChange={(value) => setFieldValue("isAssociated", value)}
                      options={[
                        { value: -1, label: "Associação" },
                        { value: 1, label: "Associado" },
                        { value: 0, label: "Não Associados" },
                      ]}
                    />
                  </Col>

                  {user.userType === 3 && (
                    <Col>
                      <Select
                        value={values.status}
                        style={{ width: "100%", paddingRight: "10px" }}
                        onChange={(value) => setFieldValue("status", value)}
                        options={[
                          { value: -1, label: "Situação do Terminal" },
                          { value: 1, label: "Instalado" },
                          { value: 2, label: "Bloqueado" },
                          { value: 3, label: "A ser instalado" },
                          { value: 4, label: "A ser bloqueado" },
                          { value: 5, label: "A ser desbloqueado" },
                          { value: 6, label: "A ser desinstalado" },
                          { value: 7, label: "Descredenciado" },
                        ]}
                      />
                    </Col>
                  )}

                  <Col>
                    <Select
                      value={values.modelName}
                      style={{ width: "100%", paddingRight: "10px" }}
                      onChange={(value) => setFieldValue("modelName", value)}
                      options={[
                        { value: -1, label: "Modelo" },
                        { value: 1, label: "A910" },
                        { value: 2, label: "L300" },
                        { value: 3, label: "A920" },
                        { value: 4, label: "S920" },
                      ]}
                    />
                  </Col>
                </>

              )}

              <Col>
                <Button
                  style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                    width: "150px",
                    backgroundColor: "#fbb651",
                  }}
                  htmlType="submit" // O clique no botão chama o onSubmit do Formik
                >
                  Consultar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
