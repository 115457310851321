import { Reducer } from 'redux';
import { UserActionTypes } from './types';
import { IUserState } from '../types';

const INITIAL_STATE: IUserState = {
  isAuthenticated: false,
  loading: false,
  userInfo: {
    id: '',
    name: '',
    email: '',
    lastName: '',
    documentNumber: '',
    phoneNumber: '',
    createdAt: new Date(),
    birthDate: new Date(),
    userType: 1,
    travaAntecipadora: false,
    commercialHierId: 1,
  },
};

const UserReducer: Reducer<IUserState> = (state: IUserState = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.Logout:
      return { ...INITIAL_STATE };
    case UserActionTypes.AuthenticateRequest:
      return {
        ...state,
        loading: true,
      };
    case UserActionTypes.AuthenticateFailure:
      return {
        ...state,
        loading: false,
      };
    case UserActionTypes.AuthenticateSuccess:
      return {
        ...state,
        loading: false,
        userInfo: { ...action.payload.userInfo },
        isAuthenticated: true,
      };
    case UserActionTypes.UpdateUserInfo:
      return {
        ...state,
      };
    case UserActionTypes.CreateNewUserRequest:
      return {
        ...state,
        loading: true,
      };
    case UserActionTypes.CreateNewUserFailure:
      return {
        ...state,
        loading: false,
      };
    case UserActionTypes.CreateNewUserSuccess:
      return {
        ...state,
        loading: false,
        userInfo: { ...action.payload.userInfo },
      };
    case UserActionTypes.ForgotPasswordRequest:
      return {
        ...state,
        loading: true,
      };
    case UserActionTypes.ForgotPasswordFailure:
      return {
        ...state,
        loading: false,
      };
    case UserActionTypes.ForgotPasswordSuccess:
      return {
        ...state,
        loading: false,
      };
    case UserActionTypes.ResetPasswordRequest:
      return {
        ...state,
        loading: true,
      };
    case UserActionTypes.ResetPasswordFailure:
      return {
        ...state,
        loading: false,
      };
    case UserActionTypes.ResetPasswordSuccess:
      return {
        ...state,
        loading: false,
      };
    case UserActionTypes.ValidateResetTokenRequest:
      return {
        ...state,
        loading: true,
      };
    case UserActionTypes.ValidateResetTokenFailure:
      return {
        ...state,
        loading: false,
      };
    case UserActionTypes.ValidateResetTokenSuccess:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default UserReducer;
