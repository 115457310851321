import axios from "axios";
import {
  IAllLiveTransactions,
  PainelVendasFilterProps
} from "../../../../global/dataTransferObjects/transacoesTempoReal";
import config from "../../../../config";


export default class VendaServices {
  getMetaData() {
    const userInfo: any = localStorage.getItem('userInfo');
    let parsedUserInfo = null;
    if (userInfo) parsedUserInfo = JSON.parse(userInfo);

    let commercialHierId = null;
    if (parsedUserInfo.commercialHierId) commercialHierId = parsedUserInfo.commercialHierId;

    const selectedCompany: string | null = localStorage.getItem('selectedCompany');
    let parsedSelectedCompany = null;
    if (selectedCompany) parsedSelectedCompany = JSON.parse(selectedCompany);

    return {
      commercialHierId: commercialHierId,
      selectedCompany: parsedSelectedCompany
    }
  }

  getVendas = async (
    page: number,
    pageSize: number,
    filter?: PainelVendasFilterProps,
    sortBy?: string

  ) => {
    const { commercialHierId, selectedCompany } = this.getMetaData();

    const paged = true;
    const dateInicial = filter?.initialDate || null;
    const dateFinal = filter?.finalDate || null;
    const status = filter?.status !== null ? filter?.status : null;
    const bandeira = filter?.bandeira !== undefined && filter?.bandeira?.length > 0 ? filter?.bandeira : null;
    const tipoPagamento = filter?.tipoPagamento !== undefined && filter?.tipoPagamento?.length > 0 ? filter?.tipoPagamento : null;
    const businessName = filter?.businessName !== undefined && filter?.businessName.length > 0 ? filter?.businessName : null;
    const acquirerNsu = filter?.acquirerNsu !== undefined && filter?.acquirerNsu.length > 0 ? filter?.acquirerNsu : null;
    const acquirer = filter?.acquirer !== null && filter?.acquirer !== -1 ? filter?.acquirer : null;
    const merchantId = selectedCompany ? selectedCompany.merchantId : filter?.merchantId !== undefined && filter?.merchantId.length > 0 ? filter?.merchantId : null;
    const orderNumber = filter?.orderNumber !== undefined && filter?.orderNumber.length > 0 ? filter?.orderNumber : null;
    const cancellationStatus = filter?.cancellationStatus !== null ? filter?.cancellationStatus : null;

    const token: any = localStorage.getItem('accessToken');
    let parsedToken = null;
    if (token) parsedToken = JSON.parse(token);

    return axios.post(`${config.wlBaseurl}/vendas/filtro`, {
      offSet: page,
      PageSize: pageSize,
      SortBy: sortBy,
      InitDate: dateInicial,
      FinishDate: dateFinal,
      TipoPagamento: tipoPagamento,
      Bandeira: bandeira,
      Status: status,
      BusinessName: businessName,
      AcquirerNsu: acquirerNsu,
      Acquirer: acquirer,
      MerchantId: merchantId,
      OrderNumber: orderNumber,
      Paged: paged,
      CommercialHierId: commercialHierId,
      Valori: true,
      CancellationStatus: cancellationStatus
    },
      {
        headers: {
          Authorization: `Bearer ${parsedToken.token}`,
        },
      })
  }

  getTotals = async (
    filter?: PainelVendasFilterProps,
    sortBy?: string

  ) => {
    const { commercialHierId, selectedCompany } = this.getMetaData();

    const dateInicial = filter?.initialDate || null;
    const dateFinal = filter?.finalDate || null;
    const status = filter?.status !== null ? filter?.status : null;
    const bandeira = filter?.bandeira !== undefined && filter?.bandeira?.length > 0 ? filter?.bandeira : null;
    const tipoPagamento = filter?.tipoPagamento !== undefined && filter?.tipoPagamento?.length > 0 ? filter?.tipoPagamento : null;
    const businessName = filter?.businessName !== undefined && filter?.businessName.length > 0 ? filter?.businessName : null;
    const acquirerNsu = filter?.acquirerNsu !== undefined && filter?.acquirerNsu.length > 0 ? filter?.acquirerNsu : null;
    const acquirer = filter?.acquirer !== null && filter?.acquirer !== -1 ? filter?.acquirer : null;
    const merchantId = selectedCompany ? selectedCompany.merchantId : filter?.merchantId !== undefined && filter?.merchantId.length > 0 ? filter?.merchantId : null;
    const orderNumber = filter?.orderNumber !== undefined && filter?.orderNumber.length > 0 ? filter?.orderNumber : null;
    const cancellationStatus = filter?.cancellationStatus !== null ? filter?.cancellationStatus : null;


    const token: any = localStorage.getItem('accessToken');
    let parsedToken = null;
    if (token) parsedToken = JSON.parse(token);

    return axios.post(`${config.wlBaseurl}/vendas/totais`, {
      SortBy: sortBy,
      InitDate: dateInicial,
      FinishDate: dateFinal,
      TipoPagamento: tipoPagamento,
      Bandeira: bandeira,
      Status: status,
      BusinessName: businessName,
      AcquirerNsu: acquirerNsu,
      Acquirer: acquirer,
      MerchantId: merchantId,
      OrderNumber: orderNumber,
      CommercialHierId: commercialHierId,
      Valori: true,
      CancellationStatus: cancellationStatus
      },
      {
        headers: {
          Authorization: `Bearer ${parsedToken.token}`,
        },
      }
    )
  }

  downloadVendas = async (
    type: string,
    filter?: PainelVendasFilterProps,
    sortBy?: string
  ) => {
    const { commercialHierId, selectedCompany } = this.getMetaData();

    const dateInicial = filter?.initialDate || null;
    const dateFinal = filter?.finalDate || null;
    const status = filter?.status !== null ? filter?.status : null;
    const bandeira = filter?.bandeira !== undefined && filter?.bandeira?.length > 0 ? filter?.bandeira : null;
    const tipoPagamento = filter?.tipoPagamento !== undefined && filter?.tipoPagamento?.length > 0 ? filter?.tipoPagamento : null;
    const businessName = filter?.businessName !== undefined && filter?.businessName.length > 0 ? filter?.businessName : null;
    const acquirerNsu = filter?.acquirerNsu !== undefined && filter?.acquirerNsu.length > 0 ? filter?.acquirerNsu : null;
    const acquirer = filter?.acquirer !== null && filter?.acquirer !== -1 ? filter?.acquirer : null;
    const merchantId = selectedCompany ? selectedCompany.merchantId : filter?.merchantId !== undefined && filter?.merchantId.length > 0 ? filter?.merchantId : null;
    const orderNumber = filter?.orderNumber !== undefined && filter?.orderNumber.length > 0 ? filter?.orderNumber : null;
    const cancellationStatus = filter?.cancellationStatus !== null ? filter?.cancellationStatus : null;


    const token: any = localStorage.getItem('accessToken');
    let parsedToken = null;
    if (token) parsedToken = JSON.parse(token);

    const result = await axios.post(`${config.wlBaseurl}/vendas/download`, {
        SortBy: 'desc',
        InitDate: dateInicial,
        FinishDate: dateFinal,
        TipoPagamento: tipoPagamento,
        Bandeira: bandeira,
        Status: status,
        BusinessName: businessName,
        AcquirerNsu: acquirerNsu,
        Acquirer: acquirer,
        MerchantId: merchantId,
        OrderNumber: orderNumber,
        Paged: false,
        CommercialHierId: commercialHierId,
        Valori: true,
        formato: type,
        CancellationStatus: cancellationStatus
      },
      {
        headers: {
          Authorization: `Bearer ${parsedToken.token}`,
        },
        responseType: 'blob'
      })

    try {
      const objectUrl = window.URL.createObjectURL(result.data);
      const a = document.createElement('a');
      a.href = objectUrl;

      if (type === 'xls') a.download = 'PainelDeVendas.xlsx';
      else  a.download = 'PainelDeVendas.csv';

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(objectUrl);
      document.body.removeChild(a);

    } catch (error) {
      console.error('Erro ao exportar tabela:', error);
    }
  }
}
