import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  user: {
    id: '',
    name: '',
    lastName: '',
    email: '',
    documentNumber: '',
    phoneNumber: '',
    createdAt: new Date(),
    birthDate: new Date(),
    userType: 1,
    travaAntecipadora: false,
    commercialHierId: 1,
  },
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.GET_USER_ME:
      return {
        ...state,
        loading: false,
        user: action.payload?.user ?? { ...InitialState.user },
      };
    default:
      return { ...state };
  }
};
