import React, {useMemo} from 'react';
import Modal from '../../../../../components/Modal';
import {createFormatProvider} from '../../../../../services/providers/factories';
import {
  ModalBody,
  TextGroup,
  Description,
  Item,
} from './styles';
import {IDetailsModalProps} from './types';

const DetailsModal: React.FC<IDetailsModalProps> = (props: IDetailsModalProps) => {
  const {politicaCobranca, isVisible, onClose} = props;
  const formatProvider = useMemo(() => createFormatProvider(), []);

  const formattedDate = useMemo(() => (date: Date) => (date !== undefined
    ? formatProvider.formatDate(Date.parse(date.toString()))
    : 'N/A'), []);

  const formattedValue = useMemo(() => (value: number): string => (value !== undefined
    ? formatProvider.formatPrice(value).replace(/[R$%]/g, '')
    : '- '), []);

  let visaCreditFee: number | undefined = 0;
  let visaCredit2x6Fee: number | undefined = 0;
  let visaCredit7x12Fee: number | undefined = 0;
  let visaCredit13x21Fee: number | undefined = 0;

  let masterCreditFee: number | undefined = 0;
  let masterCredit2x6Fee: number | undefined = 0;
  let masterCredit7x12Fee: number | undefined = 0;
  let masterCredit13x21Fee: number | undefined = 0;

  let eloCreditFee: number | undefined = 0;
  let eloCredit2x6Fee: number | undefined = 0;
  let eloCredit7x12Fee: number | undefined = 0;
  let eloCredit13x21Fee: number | undefined = 0;

  let amexCreditFee: number | undefined = 0;
  let amexCredit2x6Fee: number | undefined = 0;
  let amexCredit7x12Fee: number | undefined = 0;
  let amexCredit13x21Fee: number | undefined = 0;

  let hiperCreditFee: number | undefined = 0;
  let hiperCredit2x6Fee: number | undefined = 0;
  let hiperCredit7x12Fee: number | undefined = 0;
  let hiperCredit13x21Fee: number | undefined = 0;


  if (politicaCobranca.creditFees) {
    visaCreditFee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 1 && fee.to === 1)?.value;
    visaCredit2x6Fee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 1 && fee.to === 6)?.value;
    visaCredit7x12Fee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 1 && fee.to === 12)?.value;
    visaCredit13x21Fee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 1 && fee.to === 21)?.value;

    masterCreditFee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 2 && fee.to === 1)?.value;
    masterCredit2x6Fee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 2 && fee.to === 6)?.value;
    masterCredit7x12Fee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 2 && fee.to === 12)?.value;
    masterCredit13x21Fee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 2 && fee.to === 21)?.value;

    eloCreditFee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 171 && fee.to === 1)?.value;
    eloCredit2x6Fee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 171 && fee.to === 6)?.value;
    eloCredit7x12Fee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 171 && fee.to === 12)?.value;
    eloCredit13x21Fee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 171 && fee.to === 21)?.value;

    amexCreditFee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 3 && fee.to === 1)?.value;
    amexCredit2x6Fee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 3 && fee.to === 6)?.value;
    amexCredit7x12Fee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 3 && fee.to === 12)?.value;
    amexCredit13x21Fee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 3 && fee.to === 21)?.value;

    hiperCreditFee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 9 && fee.to === 1)?.value;
    hiperCredit2x6Fee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 9 && fee.to === 6)?.value;
    hiperCredit7x12Fee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 9 && fee.to === 12)?.value;
    hiperCredit13x21Fee = politicaCobranca.creditFees
      .find(fee => fee.bandeiraId === 9 && fee.to === 21)?.value;
  }



  return (
    <Modal
      title="Detalhes da política de cobrança:"
      centered
      visible={isVisible}
      onCancel={onClose}
      width={600}
    >
      <ModalBody>
        <TextGroup>
          <Description>Identificador:</Description>
          <Item>{politicaCobranca.id}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Nome:</Description>
          <Item>{politicaCobranca.name}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Descrição:</Description>
          <Item>{politicaCobranca.description}</Item>
        </TextGroup>
        <TextGroup>
          <Description>De:</Description>
          <Item>{formattedDate(politicaCobranca.from)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Até:</Description>
          <Item>{formattedDate(politicaCobranca.to)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Taxa de antecipação padrão:</Description>
          <Item>{`${formattedValue(politicaCobranca.anticipationFee)}%`}</Item>
        </TextGroup>
        <br/>

        <TextGroup>
          <Description>Taxa de débito Visa:</Description>
          <Item>{`${formattedValue(politicaCobranca.debitFeeVisaAdiq)}%`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Taxa de crédito à vista Visa:</Description>
          <Item>{`${formattedValue(visaCreditFee as number)}%`}</Item>
        </TextGroup>
        {visaCredit2x6Fee !== undefined && (
          <TextGroup>
            <Description>Taxa de crédito 2 x 6 Visa:</Description>
            <Item>{`${formattedValue(visaCredit2x6Fee as number)}%`}</Item>
          </TextGroup>
        )}
        {visaCredit7x12Fee !== undefined && (
          <TextGroup>
            <Description>Taxa de crédito 7 x 12 Visa:</Description>
            <Item>{`${formattedValue(visaCredit7x12Fee as number)}%`}</Item>
          </TextGroup>
        )}
        {visaCredit13x21Fee !== undefined && (
          <TextGroup>
            <Description>Taxa de crédito 13 x 21 Visa:</Description>
            <Item>{`${formattedValue(visaCredit13x21Fee as number)}%`}</Item>
          </TextGroup>
        )}
        <br/>

        <TextGroup>
          <Description>Taxa de débito Master:</Description>
          <Item>{`${formattedValue(politicaCobranca.debitFeeMasterCardAdiq)}%`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Taxa de crédito à vista Master:</Description>
          <Item>{`${formattedValue(masterCreditFee as number)}%`}</Item>
        </TextGroup>
        {masterCredit2x6Fee !== undefined && (
          <TextGroup>
            <Description>Taxa de crédito 2 x 6 Master:</Description>
            <Item>{`${formattedValue(masterCredit2x6Fee as number)}%`}</Item>
          </TextGroup>
        )}
        {masterCredit7x12Fee !== undefined && (
          <TextGroup>
            <Description>Taxa de crédito 7 x 12 Master:</Description>
            <Item>{`${formattedValue(masterCredit7x12Fee as number)}%`}</Item>
          </TextGroup>
        )}
        {masterCredit13x21Fee !== undefined && (
          <TextGroup>
            <Description>Taxa de crédito 13 x 21 Master:</Description>
            <Item>{`${formattedValue(masterCredit13x21Fee as number)}%`}</Item>
          </TextGroup>
        )}
        <br/>

        <TextGroup>
          <Description>Taxa de débito Elo:</Description>
          <Item>{`${formattedValue(politicaCobranca.debitFeeEloAdiq)}%`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Taxa de crédito à vista Elo:</Description>
          <Item>{`${formattedValue(eloCreditFee as number)}%`}</Item>
        </TextGroup>
        {eloCredit2x6Fee !== undefined && (
          <TextGroup>
            <Description>Taxa de crédito 2 x 6 Elo:</Description>
            <Item>{`${formattedValue(eloCredit2x6Fee as number)}%`}</Item>
          </TextGroup>
        )}
        {eloCredit7x12Fee !== undefined && (
          <TextGroup>
            <Description>Taxa de crédito 7 x 12 Elo:</Description>
            <Item>{`${formattedValue(eloCredit7x12Fee as number)}%`}</Item>
          </TextGroup>
        )}
        {eloCredit13x21Fee !== undefined && (
          <TextGroup>
            <Description>Taxa de crédito 13 x 21 Elo:</Description>
            <Item>{`${formattedValue(eloCredit13x21Fee as number)}%`}</Item>
          </TextGroup>
        )}
        <br/>

        <TextGroup>
          <Description>Taxa de crédito à vista Amex:</Description>
          <Item>{`${formattedValue(amexCreditFee as number)}%`}</Item>
        </TextGroup>
        {amexCredit2x6Fee !== undefined && (
          <TextGroup>
            <Description>Taxa de crédito 2 x 6 Amex:</Description>
            <Item>{`${formattedValue(amexCredit2x6Fee as number)}%`}</Item>
          </TextGroup>
        )}
        {amexCredit7x12Fee !== undefined && (
          <TextGroup>
            <Description>Taxa de crédito 7 x 12 Amex:</Description>
            <Item>{`${formattedValue(amexCredit7x12Fee as number)}%`}</Item>
          </TextGroup>
        )}
        {amexCredit13x21Fee !== undefined && (
          <TextGroup>
            <Description>Taxa de crédito 13 x 21 Amex:</Description>
            <Item>{`${formattedValue(amexCredit13x21Fee as number)}%`}</Item>
          </TextGroup>
        )}
        <br/>

        <TextGroup>
          <Description>Taxa de crédito à vista Hiper:</Description>
          <Item>{`${formattedValue(hiperCreditFee as number)}%`}</Item>
        </TextGroup>
        {hiperCredit2x6Fee !== undefined && (
          <TextGroup>
            <Description>Taxa de crédito 2 x 6 Hiper:</Description>
            <Item>{`${formattedValue(hiperCredit2x6Fee as number)}%`}</Item>
          </TextGroup>
        )}
        {hiperCredit7x12Fee !== undefined && (
          <TextGroup>
            <Description>Taxa de crédito 7 x 12 Hiper:</Description>
            <Item>{`${formattedValue(hiperCredit7x12Fee as number)}%`}</Item>
          </TextGroup>
        )}
        {hiperCredit13x21Fee !== undefined && (
          <TextGroup>
            <Description>Taxa de crédito 13 x 21 Hiper:</Description>
            <Item>{`${formattedValue(hiperCredit13x21Fee as number)}%`}</Item>
          </TextGroup>
        )}
      </ModalBody>
    </Modal>
  );
};

export default DetailsModal;
