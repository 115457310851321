import { Button as StyledButton } from '../../../styles/buttons'
import styled from "styled-components";
import {Layout} from "antd";

export const Container = styled(Layout)`
  padding: 0.5rem 1rem;
  gap: 1rem;
  position: relative;

  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const Button = styled(StyledButton)`
  width: unset;
  margin-top: 0;
  margin-left: 10px;
  align-self: flex-end;
  border-radius: 20px;
`;

