import React, {useEffect, useState} from 'react';
import {
  UserOutlined,
  BorderlessTableOutlined, IdcardOutlined, BarChartOutlined
} from '@ant-design/icons';
import { BiCopy, BiTransfer, BiTransferAlt } from 'react-icons/bi';
import { FiUsers } from 'react-icons/fi';
import { VscLiveShare } from 'react-icons/vsc';
import {
  Sider,
  Logo,
  TecpayLogo,
  Menu,
  SubMenu,
  Item,
  Link,
} from '../styles';
import { ISideMenuProps } from '../types';
import { RoutesEnum } from '../../../routes/types';
import {FaRegFilePowerpoint} from "react-icons/fa";
import {FaMobileScreenButton} from "react-icons/fa6";
import {CiShoppingCart} from "react-icons/ci";
import {IConfigData} from "../../../store/config/types";
import logo from "../../../assets/tecpay-no-bg.png";

const SideMenu: React.FC<ISideMenuProps> = (props: ISideMenuProps) => {
  const { collapsed } = props;
  const [primaryColor, setPrimaryColor] = useState('');
  const [logoUrl, setLogoUrl] = useState('');

  useEffect(() => {
    const domain = window.location.hostname;
    const cachedConfig: IConfigData = JSON.parse(localStorage.getItem(`config_${domain}`) as string);
    if (cachedConfig) {
      setPrimaryColor(cachedConfig.corPrimaria);
      setLogoUrl(cachedConfig.linkImagem2);
    } else {
      setPrimaryColor('#0e2b27');
    }
  }, []);

  return (
    <Sider
      width={230}
      className="site-layout-background"
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{backgroundColor: primaryColor}}
    >
      <Logo to={RoutesEnum.DashboardComercial}>
        <TecpayLogo src={logoUrl || logo} />
      </Logo>
      <Menu
        mode="inline"
        theme="dark"
        style={{backgroundColor: primaryColor}}
      >
        <SubMenu key="comercial" title="Comercial" icon={<UserOutlined />}>
          <Item key="comercial-1" icon={<FiUsers />}>
            <Link to="/tecpay/admin/usuarios">
              Usuários
            </Link>
          </Item>
          <Item icon={<IdcardOutlined />} key="business">
            <Link to="/tecpay/admin/empresas">
              Empresas
            </Link>
          </Item>
        </SubMenu>

        <SubMenu key="repasses" title="Transações e Repasses" icon={<BiTransfer />}>
          <Item icon={<VscLiveShare />} key="painelVendas">
            <Link to="/tecpay/admin/painel-de-vendas">
              Vendas
            </Link>
          </Item>
          <Item icon={<VscLiveShare />} key="painelRecebiveis">
            <Link to="/tecpay/admin/painel-de-recebiveis">
              Recebíveis
            </Link>
          </Item>
        </SubMenu>

        <Item icon={<FaRegFilePowerpoint />} key="cadastro-geral-1">
          <Link to="/tecpay/admin/cadastro-geral/politicas-cobranca">
            Políticas de Cobrança
          </Link>
        </Item>
        <Item icon={<FaMobileScreenButton />} key="terminaisPhoebus">
          <Link to="/terminaisPhoebus">
            Terminais
          </Link>
        </Item>
        {/*<Item icon={<FaMobileScreenButton />} key="terminais">*/}
        {/*  <Link to="/terminais">*/}
        {/*    Terminais*/}
        {/*  </Link>*/}
        {/*</Item>*/}
        <Item icon={<CiShoppingCart />} key="cadastro">
          <a href="https://app.pipefy.com/public/form/YR58XO5r" target="_blank">
            Cadastro de cliente
          </a>
        </Item>

        {/*<SubMenu key="simulador" title="Simulador" icon={<BorderlessTableOutlined />}>*/}
        {/*  <Item  key="simulador-recebimento">*/}
        {/*    <Link to="/simulador/recebimento">*/}
        {/*      Quero receber*/}
        {/*    </Link>*/}
        {/*  </Item>*/}
        {/*  <Item key="simulador-venda">*/}
        {/*    <Link to="/simulador/venda">*/}
        {/*      Quero vender por*/}
        {/*    </Link>*/}
        {/*  </Item>*/}
        {/*</SubMenu>*/}
        <Item icon={<BarChartOutlined />} key="dashboard-comercial">
          <Link to="/comercial/dashboard">
            Dashboard Comercial
          </Link>
        </Item>
      </Menu>
    </Sider>
  );
};
export default SideMenu;
