import React, {
  useReducer,
  useState,
  useCallback,
  useEffect,
} from 'react';
import FormFilter from './components/filter';
import {
  Container,
  Button,
} from './styles';
import { T2 } from '../../../styles/titles';
import { InitialState, Reducer } from './actions/reducer';
import {getAllLiveTransactions, getAllLiveTransactionsRecebiveis, getFileDownload} from './actions';
import {
  ILiveTransactionsFilterProps,
  PainelVendasFilterProps,
} from '../../../global/dataTransferObjects/transacoesTempoReal';
import LiveTable from './components/liveTable';
import LoadingOverlay from '../../../components/LoadingOverlay';
import ZeroState from '../../../components/ZeroState';
import RecebiveisServices from "./services/RecebiveisServices";
import {fi} from "date-fns/locale";

const PainelDeRecebiveis: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize] = useState<number>(20);
  const [page] = useState<number>(0);
  const [filterData, setFilterData] = useState<any>(null);
  const [totals, setTotals] = useState();
  const recebiveisServices = new RecebiveisServices();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFilter = async (filter: PainelVendasFilterProps) => {
    setFilterData(filter);
  };

  const handleDownload = async (filter?: PainelVendasFilterProps) => {
    try {
      setIsLoading(true);
      await recebiveisServices.downloadRecebiveis('xls', filter);
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleCsvDownload = async (filter?: PainelVendasFilterProps) => {
    try {
      setIsLoading(true);
      await recebiveisServices.downloadRecebiveis('csv', filter);
    } catch (error)  {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (state.zeroState) {
    return (
      <ZeroState />
    )
  }

  return (
    <Container>
      <LoadingOverlay
        show={isLoading}
        relative
      />
      <T2>Painel De Recebíveis</T2>
      <FormFilter totals={totals} onFormSubmit={handleFilter} />
      <div>
        <Button onClick={() => { handleDownload(filterData) }} style={{marginBottom: '25px', marginRight: '24px', width: '150px', alignSelf: 'start'}}>Download Xlsx</Button>
        <Button onClick={() => { handleCsvDownload(filterData) }} style={{marginBottom: '25px', width: '150px', alignSelf: 'start'}}>Download Csv</Button>
      </div>
      <LiveTable dataFilter={filterData} getTotals={(totals) => setTotals(totals)}/>
    </Container>
  );
};

export default PainelDeRecebiveis;
