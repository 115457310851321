import React, {ChangeEvent, FocusEvent, useEffect, useState} from 'react';
import { withFormik } from 'formik';
import {toast} from "react-toastify";
import {Row, Checkbox} from 'antd';
import Select from 'antd/lib/select';
import schema from './schema';
import DatePickerField from '../../../../../components/DatePicker';
import {
  DateView,
  Button,
  Form,
  Label,
  ValueCard,
  FilterRow,
  FilterContainer, Col,
} from './styles';
import {
  ILiveTransactionsFilterProps,
  PainelVendasFilterProps,
} from '../../../../../global/dataTransferObjects/transacoesTempoReal';
import { FormProps, IFormProps } from '../../types';
import { formatDate } from '../../../../../utils/formatDate';
import useGetMe from '../../../../../hooks/useCurrentUser'
import {getDefaultDate} from "../../utils";

const FormFilter: React.FC<FormProps> = (props: FormProps) => {
  const user = useGetMe();
  const { handleSubmit, totals } = props;
  const [bandeira, setBandeira] = useState('');
  const [tipoPagamento, setTipoPagamento] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [acquirerNsu, setAcquirerNsu] = useState<string>('');
  const [statusPagamento, setStatusPagamento] = useState<string>("");
  const [acquirer, setAcquirer] = useState(-1);
  const [initialDate, setInitialDate] = useState<string | Date>('');
  const [finalDate, setFinalDate] = useState<string | Date>('');
  const [initialDateFilterType, setInitialDateFilterType] = useState<string>('text');
  const [finalDateFilterType, setFinalDateFilterType] = useState<string>('text');
  const [receiveFilter, setReceiveFilter] = useState<boolean>(true);
  const [paymenteFilter, setPaymenteFilter] = useState<boolean>(false);
  const [totalGross, setTotalGross] = useState();
  const [totalLiquid, setTotalLiquid] = useState();
  const [merchantId, setMerchantId] = useState<string>('');
  const [orderNumber, setOrderNumber] = useState<string>('');
  const [cancellationStatus, setCancellationStatus] = useState<number | null>(null);

  useEffect(() => {
    props.setFieldValue('initialDate', new Date());
    props.setFieldValue('finalDate', new Date());
    setInitialDate(new Date());
    setFinalDate(new Date());
  }, []);

  const formatMoney = (value:number) => {
    const formattedValue = (value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedValue;
  };

  const getCurrentDateAsString = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    return `${dd}/${mm}/${yyyy}`;
  }

  const getValorVendaTotal = (): string => {
    let valor: number = 0;
    if (totals) valor = totals.totalGross;

    if (isNaN(valor)) valor = 0;
    const valorFormatado: string = formatMoney(valor as number);
    return valorFormatado;
  }

  const getValorLiquidoTotal = (): string => {
    let valor: number = 0;
    if (totals?.totalLiquid) valor = totals.totalLiquid;

    if (isNaN(valor)) valor = 0;
    const valorFormatado: string = formatMoney(valor as number);
    return valorFormatado;
  }

  const getValoresAReceber = (): string => {
    let valor: number = 0;
    if (totals) valor = totals.totalPending;

    if (isNaN(valor)) valor = 0;
    const valorFormatado: string = formatMoney(valor as number);
    return valorFormatado;
  }

  const getValoresPagos = (): string => {
    let valor: number = 0;
    if (totals) valor = totals.totalPayed;

    if (isNaN(valor)) valor = 0;
    const valorFormatado: string = formatMoney(valor);
    return valorFormatado;
  }

  const handleChangeBandeira = (value: string) => {
    setBandeira(value);
    props.setFieldValue('bandeira', value);
  };
  const handleChangeTipoPagamento = (value: string) => {
    setTipoPagamento(value);
    props.setFieldValue('tipoPagamento', value);
  };

  const handleChangeStatus = (value: string) => {
    setStatusPagamento(value);
    props.setFieldValue('statusPagamento', value);
  };

  const handleBusinessNameChange = (event: any) => {
    setBusinessName(event.target.value);
    props.setFieldValue('businessName', event.target.value);
  };

  const handleAcquirerNsuChange = (event: any) => {
    setAcquirerNsu(event.target.value);
    props.setFieldValue('acquirerNsu', event.target.value);
  };

  const handleMerchantIdChange = (event: any) => {
    setMerchantId(event.target.value);
    props.setFieldValue('merchantId', event.target.value);
  };

  const handleChangeAcquirer = (value: number) => {
    setAcquirer(Number(value));
    props.setFieldValue('acquirer', Number(value));
  };

  const handleOrderNumber = (event: ChangeEvent<HTMLInputElement>): void => {
    setOrderNumber(event.target.value);
    props.setFieldValue('orderNumber', event.target.value);
  };

  const handleReceiveFilterSelection = (value: any) => {
    if (value.target.checked) {
      setReceiveFilter(true);
      setPaymenteFilter(false);
      props.setFieldValue('saleInitialDate', undefined);
      props.setFieldValue('saleFinalDate', undefined);
    } else {
      setReceiveFilter(false);
      props.setFieldValue('initialDate', undefined);
      props.setFieldValue('finalDate', undefined);
    }
  };

  const handlePaymentFilterSelection = (value: any) => {
    if (value.target.checked) {
      setPaymenteFilter(true);
      setReceiveFilter(false);
      props.setFieldValue('initialDate', undefined);
      props.setFieldValue('finalDate', undefined);
    } else {
      setPaymenteFilter(false);
      props.setFieldValue('saleInitialDate', undefined);
      props.setFieldValue('saleFinalDate', undefined);
    }
  };

  const handleInitialDate = (event: ChangeEvent<HTMLInputElement>) => {
    setInitialDate(event.target.value);
    props.setFieldValue('initialDate', event.target.value);
  };

  const handleFinalDate = (event: ChangeEvent<HTMLInputElement>) => {
    setFinalDate(event.target.value);
    props.setFieldValue('finalDate', event.target.value);
  };

  const handleSaleInitialDate = (event: ChangeEvent<HTMLInputElement>) => {
    setInitialDate(event.target.value);
    props.setFieldValue('saleInitialDate', event.target.value);
  };

  const handleSaleFinalDate = (event: ChangeEvent<HTMLInputElement>) => {
    setFinalDate(event.target.value);
    props.setFieldValue('saleFinalDate', event.target.value);
  };

  const handleFocusInitialDateFilter = (event: FocusEvent<HTMLInputElement>) => {
    setInitialDateFilterType('date');
    if (!event.target.value) {

      const stringDate: string = getDefaultDate();
      event.target.value = stringDate;

      setInitialDate(event.target.value);
      props.setFieldValue('initialDate', event.target.value);
    }
  };

  const handleFocusSaleInitialDateFilter = (event: FocusEvent<HTMLInputElement>) => {
    setInitialDateFilterType('date');
    if (!event.target.value) {

      const stringDate: string = getDefaultDate();
      event.target.value = stringDate;

      setInitialDate(event.target.value);
      props.setFieldValue('saleInitialDate', event.target.value);
    }
  };

  const handleFocusFinalDateFilter = (event: FocusEvent<HTMLInputElement>) => {
    setFinalDateFilterType('date');
    if (!event.target.value) {

      const stringDate: string = getDefaultDate();
      event.target.value = stringDate;

      setFinalDate(event.target.value);
      props.setFieldValue('finalDate', event.target.value);
    }
  };

  const handleFocusSaleFinalDateFilter = (event: FocusEvent<HTMLInputElement>) => {
    setFinalDateFilterType('date');
    if (!event.target.value) {

      const stringDate: string = getDefaultDate();
      event.target.value = stringDate;

      setFinalDate(event.target.value);
      props.setFieldValue('saleFinalDate', event.target.value);
    }
  };

  const handleChangeCancellationStatus = (value: number | null): void => {
    setCancellationStatus(value);
    props.setFieldValue('cancellationStatus', value);
  };

  return (
    <>
      <ValueCard>
        <div>
          <p style={{fontWeight: 'bolder'}}>Valor Líquido: </p>
          <p>{getValorLiquidoTotal()}</p>
        </div>
        <div>
          <p style={{fontWeight: 'bolder'}}>Valor das Vendas: </p>
          <p>{getValorVendaTotal()}</p>
        </div>
        <div>
          <p style={{fontWeight: 'bolder'}}>Valores Pagos: </p>
          <p>{getValoresPagos()}</p>
        </div>
        <div>
          <p style={{fontWeight: 'bolder'}}>Valores a Receber: </p>
          <p>{getValoresAReceber()}</p>
        </div>
      </ValueCard>

      <Form onSubmit={handleSubmit}>
        <Row>
          <Label style={{marginBottom: '7px'}}>Filtrar por:</Label>
        </Row>
        <Row>
          <Col>
            <Checkbox
              onChange={handleReceiveFilterSelection}
              checked={receiveFilter}
            >Data de recebimento
            </Checkbox>
          </Col>
          <Col>
            <Checkbox
              onChange={handlePaymentFilterSelection}
              checked={paymenteFilter}
            >Data de venda
            </Checkbox>
          </Col>
        </Row>
        <Row
          gutter={16}
        >
          {receiveFilter && (
            <>
              <Col>
                <input type={initialDateFilterType} onChange={handleInitialDate} onFocus={handleFocusInitialDateFilter}
                       name="initialDate" placeholder="Data inicial" />
              </Col>
              <Col>
                <input type={finalDateFilterType} onChange={handleFinalDate} onFocus={handleFocusFinalDateFilter}
                       name="finalDate" placeholder="Data final"/>
              </Col>
            </>
          )}
          {paymenteFilter && (
            <>
              <Col>
                <input type={initialDateFilterType} onChange={handleSaleInitialDate} onFocus={handleFocusSaleInitialDateFilter}
                       name="saleInitialDate" placeholder="Data inicial"/>
              </Col>
              <Col>
                <input type={finalDateFilterType} onChange={handleSaleFinalDate} onFocus={handleFocusSaleFinalDateFilter}
                       name="saleFinalDate" placeholder="Data final"/>
              </Col>
            </>
          )}
          <Col>
            <Select
              defaultValue=""
              style={{width: '100%'}}
              onChange={handleChangeBandeira}
              options={[
                {
                  value: '',
                  label: 'Bandeira',
                },
                {
                  value: '3',
                  label: 'AMEX',
                },
                {
                  value: '171',
                  label: 'ELO',
                },
                {
                  value: '9',
                  label: 'HIPERCARD',
                },
                {
                  value: '2',
                  label: 'MASTERCARD',
                },
                {
                  value: '1',
                  label: 'VISA',
                },
                {
                  value: '999',
                  label: 'PIX',
                },
              ]}
            />
          </Col>
          <Col>
            <Select
              defaultValue=""
              style={{width: '100%'}}
              onChange={handleChangeTipoPagamento}
              options={[
                {
                  value: '',
                  label: 'Tipo pagamento',
                },
                {
                  value: '001',
                  label: 'Crédito à Vista',
                },
                {
                  value: '002',
                  label: 'Parcelamento sem Juros',
                },
                {
                  value: '003',
                  label: 'Parcelamento com Juros',
                },
                {
                  value: '004',
                  label: 'Cancelamento',
                },
                {
                  value: '005',
                  label: 'Débito',
                },
                {
                  value: '006',
                  label: 'Pré-Autorização',
                },
                {
                  value: '099',
                  label: 'Pix',
                },
              ]}
            />
          </Col>
          <Col>
            <input placeholder="NSU" onChange={handleAcquirerNsuChange}/>
          </Col>
          {(user.userType === 3 || user.userType === 6 || user.userType === 7) && (
            <>
              <Col>
                <input placeholder="Empresa" onChange={handleBusinessNameChange}/>
              </Col>
              <Col>
                <input placeholder="Cod. Lojista" onChange={handleMerchantIdChange}/>
              </Col>
            </>
          )}
          <Col>
            <Select
              defaultValue=""
              style={{width: '100%'}}
              onChange={handleChangeStatus}
              options={[
                {
                  value: '',
                  label: 'Status',
                },
                {
                  value: 'PENDING',
                  label: 'Pendente',
                },
                {
                  value: 'CANCELLED',
                  label: 'Cancelado',
                },
                {
                  value: 'PAYED',
                  label: 'Pago',
                }
              ]}
            />
          </Col>
          {user.userType === 3 && (
            <Col>
              <Select
                defaultValue={-1}
                style={{width: '100%'}}
                onChange={handleChangeAcquirer}
                options={[
                  {
                    value: -1,
                    label: 'Adquirente',
                  },
                  {
                    value: 3,
                    label: 'Adiq',

                  },
                  {
                    value: 10,
                    label: 'Rede',
                  },
                  {
                    value: 99,
                    label: 'Valori',
                  },
                ]}
              />
            </Col>
          )}
          <Col>
            <input placeholder="Id Pedido" onChange={handleOrderNumber}/>
          </Col>
          <Col xs={{ flex: '100%' }}
               sm={{ flex: '50%' }}
               md={{ flex: '40%' }}
               lg={{ flex: '20%' }}
               xl={{ flex: '10%' }}>
            <Select
              defaultValue={null}
              style={{width: '100%'}}
              onChange={handleChangeCancellationStatus}
              options={[
                {
                  value: null,
                  label: 'Status Cancelamento',
                },
                {
                  value: -2,
                  label: 'Parcialmente Cancelada',
                },
              ]}
            />
          </Col>
          <Col>
            <Button style={{background: '#fbb651'}} htmlType="submit">
              Consultar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
export default withFormik<IFormProps, PainelVendasFilterProps>({
  mapPropsToValues: (): PainelVendasFilterProps => ({
    initialDate: '',
    finalDate: '',
    saleInitialDate: '',
    saleFinalDate: '',
    bandeira: '',
    statusPagamento: '',
    tipoPagamento: '',
    businessName: '',
    acquirerNsu: '',
    orderNumber: '',
    acquirer: -1,
    cancellationStatus: null,
  }),
  validationSchema: schema,
  handleSubmit: (values, { props }) => {
    const initialDateValue = values.initialDate;
    const finalDateValue = values.finalDate;

    const saleInitialDateValue = values.saleInitialDate;
    const saleFinalDateValue = values.saleFinalDate;

    const test = {
      ...values,
      initialDate: initialDateValue,
      finalDate: finalDateValue,
      saleInitialDate: saleInitialDateValue,
      saleFinalDate: saleFinalDateValue
    }

    props.onFormSubmit(test);
  },
})(FormFilter);
