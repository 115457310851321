import React, {ChangeEvent, useEffect, useMemo, useReducer, useRef, useState} from 'react';
import Table, {TableColumn} from '../../../../../components/Table';
import {ITransactionsTable} from '../../types';
import {createFormatProvider} from '../../../../../services/providers/factories';
import {ResponsiveColumn, ResponsiveRow, ResponsiveTable, TableContainer} from '../../styles';
import useGetMe from "../../../../../hooks/useCurrentUser";
import {
  clearTokens,
  formatAcquirer,
  formatChannel,
  formatCommercialHierarchy,
  formatDateView,
  formatMoney,
  formatStatus
} from "../../utils";
import {InitialState, Reducer} from "../../actions/reducer";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import {formatDate} from "../../../../../utils/formatDate";
import Select from "antd/lib/select";
import VendaServices from "../../services/VendaServices";
import { useHistory } from 'react-router-dom';
import {getErrorMessage} from "../../../../../utils/getErrorMessage";

interface DataType {
  paymentDate: string;
  tempo: string;
  empresa: string;
  document: string;
  businessName: string;
  acquirer: string;
  terminal: string;
  tefTerminal: string;
  brand: string;
  authorizationNumber: string;
  cardNumber: string;
  productName: string;
  acquirerNsu: string;
  originalValue: string;
  status: string;
  parcels: string;
  terminalSerialNumber: string;
  liquidValue: string;
  value: string;
  merchant: string;
  captureChannel: string;
  orderNumber: string;
}

type DataIndex = keyof DataType;

const LiveTable: React.FC<ITransactionsTable> = (props: ITransactionsTable) => {
  const {dataFilter, getTotals} = props;
  const vendasServices = new VendaServices();
  const history = useHistory();
  const user = useGetMe();
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [totalGross, setTotalGross] = useState(0);
  const [totalLiquid, setTotalLiquid] = useState(0);
  const [count, setCount] = useState(0);
  const [averageTicket, setAverageTicket] = useState(0);
  const [externalTotalGross, setExternalTotalGross] = useState(0);
  const [externalTotalLiquid, setExternalTotalLiquid] = useState(0);
  const [externalCount, setExternalCount] = useState(0);
  const [externalAverageTicket, setExternalAverageTicket] = useState(0);
  const [sortBy, setSortBy] = useState('desc');
  const searchInput = useRef(null);
  const formatProvider = useMemo(() => createFormatProvider(), []);
  const [liveTransactions, setLiveTransactions] = useState<any[]>([]);
  const [externalLiveTransactionsRecords, setExternalLiveTransactionsRecords] = useState<any[]>([]);
  const [externalLiveTransactions, setExternalLiveTransactions] = useState<any>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  let today: Date = new Date();
  let tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  today.setHours(0, 0, 0, 0);
  tomorrow.setHours(0 ,0 ,0 ,0);

  const [dataFilterState, setDataFilterState] = useState({
    initialDate: formatDate(today),
    finalDate: formatDate(tomorrow),
    status: 1
  });

  const formattedDate = (date: string) => formatDateView(date);

  const handleSortBy = (value: string): void => {
    setSortBy(value);
  };

  const filter = (type: string) => {
    const handleAdquirenteFilter = (value: any, record: any) => {
      return record[type].toLowerCase().includes(value.toLowerCase());
    };

    return handleAdquirenteFilter;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await vendasServices.getVendas(
          currentPage - 1,
          pageSize,
          dataFilterState,
          sortBy
        ).then(response => {
          setExternalLiveTransactions(response.data);
          setExternalLiveTransactionsRecords(response.data.records);
        })
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        console.log(errorMessage);

        if (errorMessage === 'Request failed with status code 401') {
          window.location.assign(`${window.location.origin}/login`);
        }
      }

      try {
        await vendasServices.getTotals(
          dataFilterState,
          sortBy
        ).then(response => {
          if (response.data.totalGross === null) setExternalTotalGross(0);
          else setExternalTotalGross(response.data.totalGross);

          if (response.data.totalLiquid === null) setExternalTotalLiquid(0);
          else setExternalTotalLiquid(response.data.totalLiquid);

          if (response.data.quantidade === null) setExternalCount(0);
          else setExternalCount(response.data.quantidade);

          if (response.data.averageTicket) setExternalAverageTicket(0);
          else setExternalAverageTicket(response.data.averageTicket);
        })
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        console.log(errorMessage);

        if (errorMessage === 'Request failed with status code 401') {
          clearTokens();
          window.location.assign(`${window.location.origin}/login`);
        }
      }

      finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [dataFilterState]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        await vendasServices.getVendas(
          currentPage - 1,
          pageSize,
          dataFilterState,
          sortBy
        ).then(response => {
          setExternalLiveTransactions(response.data);
          setExternalLiveTransactionsRecords(response.data.records);
        })
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        console.log(errorMessage);

        if (errorMessage === 'Request failed with status code 401') {
          clearTokens();
          window.location.assign(`${window.location.origin}/login`);
        }
      }
      finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [sortBy, currentPage, pageSize]);

  useEffect(() => {
    if (dataFilter) {
      setCurrentPage(1);
      setDataFilterState(dataFilter);
    }
  }, [dataFilter]);

  useEffect(() => {
    if (externalTotalGross === null) setTotalGross(0);
    else setTotalGross(externalTotalGross);

    if (externalTotalLiquid === null) setTotalLiquid(0);
    else setTotalLiquid(externalTotalLiquid);

    if (externalCount === null) setCount(0);
    else setCount(externalCount);

    setAverageTicket(0);
  }, [externalCount, externalTotalGross]);

  useEffect(() => {
    if (getTotals) {
      getTotals({
        totalGross: totalGross,
        totalLiquid: totalLiquid,
        averageTicket: averageTicket,
        count: count
      })
    }
  }, [totalGross, totalLiquid, count]);

  useEffect(() => {
    setLiveTransactions(externalLiveTransactionsRecords);
  }, [externalLiveTransactionsRecords]);

  return (
    <div>
      <LoadingOverlay show={isLoading} />
      <Select
          defaultValue="Ordenar"
          style={{width: 200, marginBottom: 20}}
          onChange={handleSortBy}
          options={[
            {
              value: 'asc',
              label: 'Mais antigo',
            },
            {
              value: 'desc',
              label: 'Mais recente',
            },
          ]}
      />
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <TableContainer>
        <Table
          dataSource={liveTransactions}
          pagination={
            {
              defaultPageSize: 20,
              defaultCurrent: 0,
              current: currentPage,
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
                setCurrentPage(current);
              },
              total: externalCount,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
              showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} vendas`
            }
          }
        >
          <TableColumn title="Data/Hora" dataIndex="paymentDate" key="paymentDate" render={formattedDate} onFilter={filter('paymentDate')} />
          <TableColumn title="CNPJ/CPF" dataIndex="document" key="document" />
          {(user.userType === 3 || user.userType === 6 || user.userType === 7) && (
            <TableColumn title="Empresa" dataIndex="businessName" key="businessName" onFilter={filter('businessName')} />
          )}
          {(user.userType === 3 || user.userType === 6 || user.userType === 7) && (
            <TableColumn title="Codigo do lojista" dataIndex="merchant" key="merchant" onFilter={filter('merchant')} />
          )}
          <TableColumn title="Terminal" dataIndex="tefTerminal" key="tefTerminal" onFilter={filter('tefTerminal')} />
          <TableColumn title="NSU" dataIndex="acquirerNsu" key="acquirerNsu" onFilter={filter('acquirerNsu')} />
          <TableColumn title="Cod. Autorização" dataIndex="authorizationNumber" key="authorizationNumber" />
          <TableColumn title="Id do Pedido" dataIndex="orderNumber" key="orderNumber" />
          <TableColumn title="Tipo Pagamento" dataIndex="productName" key="productName" onFilter={filter('productName')} />
          <TableColumn title="Canal do pagamento" render={formatChannel} dataIndex="captureChannel" key="captureChannel" />
          <TableColumn title="Parcelas" dataIndex="parcels" key="parcels" onFilter={filter('parcels')} />
          <TableColumn title="Bandeira" dataIndex="brand" key="brand" onFilter={filter('brand')} />
          <TableColumn title="No. Cartao" dataIndex="cardNumber" key="cardNumber" onFilter={filter('cardNumber')} />
          <TableColumn title="Valor Da Venda" render={formatMoney} dataIndex="value" key="value" onFilter={filter('value')} />
          <TableColumn title="Valor Líquido" render={formatMoney} dataIndex="liquidValue" key="liquidValue" onFilter={filter('liquidValue')} />
          {user.userType === 3 && (
            <TableColumn title="Adquirente" render={formatAcquirer} dataIndex="acquirer" key="acquirer" onFilter={filter('acquirer')} />
          )}
          {user.userType === 3 && (
            <TableColumn title="Comercial" render={formatCommercialHierarchy} dataIndex="commercialHierId" key="commercialHierId" onFilter={filter('commercialHierId')} />
          )}
          <TableColumn title="Status" render={formatStatus} dataIndex="status" key="status" onFilter={filter('status')} />
        </Table>
      </TableContainer>

      <ResponsiveTable>
        <ResponsiveRow style={{ borderBottom: '1px solid gray', borderRadius: '0px'}}>
          <ResponsiveColumn><h4>Data e Status</h4></ResponsiveColumn>
          <ResponsiveColumn><h4>Pagamento e Bandeira</h4></ResponsiveColumn>
          <ResponsiveColumn><h4>Valores</h4></ResponsiveColumn>
        </ResponsiveRow>
        {liveTransactions.map((sale) => {
          return (
            <ResponsiveRow style={{backgroundColor: "white", boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.2)"}}>
              <ResponsiveColumn style={{maxWidth: "92px"}}>
                <p>{formatDateView(sale.paymentDate)}</p>
                <p style={formatStatus(sale.status) === 'Confirmada' ? {color: 'green', fontWeight: 'bold'} : {color: 'red', fontWeight: 'bold'}}>{formatStatus(sale.status)}</p>
              </ResponsiveColumn>
              <ResponsiveColumn>
                <p>{sale.productName} - {sale.parcels}x</p>
                <p><b>{sale.brand}</b></p>
              </ResponsiveColumn>
              <ResponsiveColumn style={{maxWidth: "88px"}}>
                <p>Bruto: {formatMoney(sale.value)}</p>
                <p><b>Líquido: {formatMoney(sale.liquidValue)}</b></p>
              </ResponsiveColumn>
            </ResponsiveRow>
          )
        })}
      </ResponsiveTable>
    </div>
  )
}

export default LiveTable;
