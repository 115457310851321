import {IDetailsModalProps} from "./types";
import {Modal} from "antd";
import {ModalBody,
  TextGroup} from "./styles";
import {Description,
  Item} from "./styles";
import React from "react";

const DetailsModal: React.FC<IDetailsModalProps> = (props: IDetailsModalProps) => {
  const {terminals, isVisible, onClose} = props;
  console.log(terminals);

  return (
    <Modal
      title="Detalhes Terminais"
      centered
      visible={isVisible}
      onCancel={onClose}
      width={600}
    >
      <ModalBody>
        <TextGroup>
          <Description>Núm. de POS:</Description>
          <Item>{terminals.count}</Item>
        </TextGroup>
        <br/>

        {terminals.records.map((terminal: any) => {
          return (
            <>
              <TextGroup>
                <Description>Terminal:</Description>
                <Item>{terminal.terminal}</Item>
              </TextGroup>
              <TextGroup>
                <Description>Tid:</Description>
                <Item>{terminal.tefTerminal}</Item>
              </TextGroup>
              <TextGroup>
                <Description>Número de série:</Description>
                <Item>{terminal.serialNumber}</Item>
              </TextGroup>
              <TextGroup>
                <Description>Status:</Description>
                <Item>{terminal.status}</Item>
              </TextGroup>
              <br/>
            </>
          )
        })}
      </ModalBody>
    </Modal>
  )
}

export default DetailsModal;
