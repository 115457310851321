import { IConfig } from './types';

const Config: IConfig = {
  // apiBaseUrl: 'https://localhost:44371/api',
  apiBaseUrl: 'https://api-app-service-prod.azurewebsites.net/api',
  ibgeBaseUrl: 'https://servicodados.ibge.gov.br/api',
  viaCepBaseUrl: 'https://viacep.com.br/ws',
  wlBaseurl: 'https://link.valori.com.vc:8485/api',
  pagamentosUrl: 'https://link.valori.com.vc:8486/api',
}

export default Config;
