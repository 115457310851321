import TerminaisPhoebusService from "./service/TerminaisPhoebusService";
import {useEffect, useState} from "react";
import {Button, Container} from "./styles";
import { T2 } from "../../styles/titles";
import LiveTable from "./components/liveTable";
import { FormFilter } from "./components/filter";
import LoadingOverlay from "../../components/LoadingOverlay";
import {FormValues, TerminalsTableData} from "./types";
import {toast} from "react-toastify";
import useGetMe from "../../hooks/useCurrentUser";

const TerminaisPhoebus: React.FC = () => {
  const user = useGetMe();
  const [filterData, setFilterData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<TerminalsTableData | null>(null);
  const [pageSize, setPageSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const terminaisPhoebusService = new TerminaisPhoebusService();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await terminaisPhoebusService.getAllTerminals(
        (currentPage - 1) * pageSize,
        pageSize * currentPage,
        filterData,
        undefined,
        user);
      setTableData({
        records: data.records || [],
        count: data.count || 0,
      });
    } catch (error) {
      console.error("Erro ao buscar os dados:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (filterData) {
      fetchData();
    }
  }, [filterData, currentPage, pageSize]);

  const handleDownload = async (filter?: FormValues) => {
    setIsLoading(true)
    const response = await terminaisPhoebusService.getTerminalDownload(
      (currentPage - 1) * pageSize,
      pageSize * currentPage,
      filter,
      user
    );

    if (response) {
      const objectUrl = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = objectUrl;
      a.download = 'Terminais.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(objectUrl);
      document.body.removeChild(a);
    }
    setIsLoading(false)
  }

  const handleJob = async () =>
  {
    const response = await terminaisPhoebusService.forceTerminalJob();

    return toast.success("Job Executado com sucesso!");
  }

  return (
    <Container>
      <LoadingOverlay
        show={isLoading}
        relative
      />

      <T2>Terminais Phoebus</T2>
      <FormFilter getFilters={(data) => setFilterData(data)} />

      <div>
        <Button
          onClick = {() => {
            handleDownload(filterData);
          }}
          style={{
            marginBottom: '25px',
            marginRight: '24px',
            width: '150px',
            alignSelf: 'start'
          }}
        >
          Download xlsx
        </Button>

        {/*<Button*/}
        {/*  onClick = {() => {*/}
        {/*    handleJob();*/}
        {/*  }}*/}
        {/*  style={{*/}
        {/*    marginBottom: '25px',*/}
        {/*    marginRight: '24px',*/}
        {/*    width: '150px',*/}
        {/*    alignSelf: 'start'*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Forçar Job*/}
        {/*</Button>*/}
      </div>

      {tableData && <LiveTable
        data={tableData}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={setCurrentPage}
        onPageSizeChange={setPageSize}
      />
      }
    </Container>
  );
};

export default TerminaisPhoebus;
