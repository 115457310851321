import React, {
  useReducer,
  useState,
  useCallback,
  useEffect,
} from 'react';
import FormFilter from './components/filter';
import {
  Container,
  Button,
} from './styles';
import { T2 } from '../../../styles/titles';
import {
  PainelVendasFilterProps,
} from '../../../global/dataTransferObjects/transacoesTempoReal';
import LiveTable from './components/liveTable';
import LoadingOverlay from '../../../components/LoadingOverlay';
import ZeroState from '../../../components/ZeroState';
import {formatDate} from "../../../utils/formatDate";
import {InitialState, Reducer} from "../painelDeRecebiveis/actions/reducer";
import PainelDePagamentosService from "./service/PainelDePagamentosService";
import {getErrorMessage} from "../../../utils/getErrorMessage";
const painelDePagamentosService = new PainelDePagamentosService();
const PainelDePagamentos: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize] = useState<number>(20);
  const [page] = useState<number>(0);
  const [filterData, setFilterData] = useState<any>(null);
  const [pagamentos, setPagamentos] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [externalPayments, setExternalPayments] = useState<any[]>([]);
  const [internalPayments, setInternalPayments] = useState<any[]>([]);

  let today: Date = new Date();
  let tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  today.setHours(0, 0, 0, 0);
  tomorrow.setHours(0 ,0 ,0 ,0);

  useEffect(() => {
    setTimeout(() => {
      handleFilter({
        initialDate: formatDate(today),
        finalDate: formatDate(today),
        tipoPagamento:'0',
        businessName:'',
        Valori: true,
      })
    }, 200);
  }, []);


  const handleFilter = async (filter: any) => {
    try {
      filter.Valori = true;
      setLoading(true);
      setFilterData(filter);

      await painelDePagamentosService.getPagamentos(filter)
        .then(response => {
          setExternalPayments(response.data.records);
        })
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      console.log(errorMessage);

      if (errorMessage === 'Request failed with status code 401') {
        window.location.assign(`${window.location.origin}/login`);
      }
    }
    finally {
      setLoading(false);
    }
  };

  const handleDownload = async (filter?: PainelVendasFilterProps) => {
    try {
      setLoading(true);
      await painelDePagamentosService.downloadPagamentos('xls', filter);
    }
    catch (error) {
      const errorMessage = getErrorMessage(error);
      console.log(errorMessage);

      if (errorMessage === 'Request failed with status code 401') {
        window.location.assign(`${window.location.origin}/login`);
      }
    }
    finally {
      setLoading(false);
    }
  };

  if (state.zeroState) {
    return (
      <ZeroState />
    )
  }

  useEffect(() => {
    setPagamentos(externalPayments);
  }, [externalPayments]);

  return (
    <Container>
      <LoadingOverlay
        show={loading}
        relative
      />
      <T2>Painel De Pagamentos</T2>
      <FormFilter setLoading={setLoading} loading={loading} liveTransactions={internalPayments} externalLiveTransactions={externalPayments}  onFormSubmit={handleFilter} filter={filterData} />
      <Button onClick={() => {handleDownload(filterData) }} style={{marginBottom: '25px', marginRight: '24px', width: '150px', alignSelf: 'start'}}>Download Xlsx</Button>
      <LiveTable setPagamentos={setPagamentos} liveTransactions={pagamentos} />
    </Container>
  );
};
export default PainelDePagamentos;
