import {FormValues} from "../types";
import {getAxiosInstance} from "../../../../services/providers/api";
import config from "../../../../config";
import axios from "axios";

export default class CancelamentoService {
  cancelTransactions = async (filter: FormValues) => {
    const merchant_id = filter?.merchant_id || null;
    const transaction_date = filter?.transaction_date || null;
    const nsu = filter?.nsu || null;
    const authorization_code = filter?.authorization_code || null;
    const value_tx_ori = filter?.value_tx_ori || null;
    const cancel_amount = filter?.cancel_amount || null;
    const cancel_observations = filter?.cancel_observations || null;
    const codigo_lojista = filter?.codigo_lojista || null;
    const acquirer = filter.acquirer;
    const userId = JSON.parse(localStorage.getItem("userInfo") as string).id;
    const userName = JSON.parse(localStorage.getItem("userInfo") as string).name;

    if (acquirer === 'adiq') {
      try {
        const response = await getAxiosInstance().post('/cancel', {
          merchant_id,
          transaction_date,
          nsu,
          authorization_code,
          value_tx_ori,
          cancel_amount,
          cancel_observations,
          codigo_lojista,
          acquirer,
          userId,
          userName
        });

        if (response.data.response.success !== false) {
          await axios.post(`${config.pagamentosUrl}/pagamentos/cancel`, {
            merchant_id,
            transaction_date,
            nsu,
            authorization_code,
            value_tx_ori,
            cancel_amount,
            cancel_observations,
            codigo_lojista,
            acquirer
          });
        }
        return response.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    }
    else if (acquirer === 'rede') {
      try {
        const responseStatusUpdate = await axios.post(`${config.pagamentosUrl}/pagamentos/cancel`, {
          merchant_id,
          transaction_date,
          nsu,
          authorization_code,
          value_tx_ori,
          cancel_amount,
          cancel_observations,
          codigo_lojista,
          acquirer
        });

        if (responseStatusUpdate.status === 200) {
          const response = await getAxiosInstance().post('/cancel', {
            merchant_id,
            transaction_date,
            nsu,
            authorization_code,
            value_tx_ori,
            cancel_amount,
            cancel_observations,
            codigo_lojista,
            acquirer,
            userId,
            userName
          });

          return response.data;
        }
      }
      catch (error) {
        console.log(error);
        throw error;
      }
    }
  };

  getAllCancelTransactions = async () => {
    return getAxiosInstance().get("/cancel/all");
  }
}
