export const formatMoney = (value:number) => {
  const formattedValue = (value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formattedValue;
};

export const formatDateView = (dateString:any) => {
  const date = new Date(dateString);

  // Pad single digits with leading zero
  // @ts-ignore
  const pad = (num) => (num < 10 ? `0${num}` : num);

  const formattedDate = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`;

  return formattedDate;
};

export const formatSaleDateView = (dateString:any) => {
  const date = new Date(dateString);

  // Pad single digits with leading zero
  // @ts-ignore
  const pad = (num) => (num < 10 ? `0${num}` : num);

  const formattedDate = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()} ${pad(date.getHours())}:${pad(date.getUTCMinutes())}:${pad(date.getUTCSeconds())}`;

  return formattedDate;
};

export const formatStatus = (status: any) => {
  let statusStr = status;
  if (status === 'CANCELADO') {
    statusStr = 'Cancelado';
  } else if (status === 'PENDENTE') {
    statusStr = 'Pendente';
  } else if (status === 'PAGO') {
    statusStr = 'Pago';
  } else if (status === 'CANCELADO PARCIAL') {
    statusStr = 'Cancelado parcialente';
  }

  return statusStr;
};

export const formatAcquirer = (acquirer :any) => {
  let acquirerStr = '';
  if (acquirer === 3) {
    acquirerStr = 'Adiq';
  } else if (acquirer === 10) {
    acquirerStr = 'Rede'
  }else if (acquirer === 99) {
    acquirerStr = 'Valori'
  }
  return acquirerStr;
};

export const formatChannel = (channel: string): string => {
  if (channel === 'PHYSICAL') return 'Físico';
  else if (channel === 'DIGITAL') return 'Digital';
  else return '';
}

export const getDefaultDate = () => {
  const now: Date = new Date();
  const year: number = now.getFullYear();
  const month: string = String(now.getMonth() + 1).padStart(2, '0');
  const day: string = String(now.getDate()).padStart(2, '0');

  const stringDate: string = `${year}-${month}-${day}`;

  return stringDate;
};
