import React, {useEffect, useState} from "react";
import {Button, Container, FilterContainer, FilterRow, Label} from "./styles";
import {T2} from "../../../styles/titles";
import {FormValues} from "./types";
import {Form, Formik} from "formik";
import {Col, Row} from "antd";
import {toast} from "react-toastify";
import GerenciadorEdiService from "./services/gerenciadorEdiService";
import LoadingOverlay from "../../../components/LoadingOverlay";
import {FormInput} from "../../../styles/inputs";
import PainelDeDepositosService from "../../transacoesErepasses/painelDeDepositos/service/painelDeDepositosService";


const GerenciadorEdi: React.FC = () => {
  const gerenciadorEdiService = new GerenciadorEdiService();
  const [date, setDate] = useState<any>(new Date());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const painelDeDepositosService = new PainelDeDepositosService();

  const handleDate = (e: any) => {
    const rawValue = e.target.value;
    setDate(rawValue);
  };

  const downloadApi = async (submit: any) => {
    setIsLoading(true);
    const referenceDate = submit.referenceDate;
    if (referenceDate) {
      const [year, month, day] = referenceDate.split('-');
      const formattedDate = `${month}/${day}/${year}`; // MM/DD/YYYY
      submit.referenceDate = formattedDate;
    }

    const response = await gerenciadorEdiService.downloadEdi(submit);

    if (response.status === 200) {
      toast.success("Download realizado com sucesso!");
      setIsLoading(false);
    } else {
      toast.error("Donwload falhou!")
      setIsLoading(false)
    }
  };

  const processarApi = async (submit: any) => {
    setIsLoading(true);
    const referenceDate = submit.referenceDate;
    if (referenceDate) {
      const [year, month, day] = referenceDate.split('-');
      const formattedDate = `${month}/${day}/${year}`; // MM/DD/YYYY
      submit.referenceDate = formattedDate;
    }

    const response = await gerenciadorEdiService.updateEdi(submit);

    if (response) {
      toast.success("Download realizado com sucesso!");
      setIsLoading(false);
    } else {
      toast.error("Donwload falhou!")
      setIsLoading(false)
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    setIsLoading(true);
    const response = await painelDeDepositosService.uploadFile(selectedFile);

    if (response.status >= 200 && response.status < 300) {
      toast.success("Arquivo enviado com sucesso!");
      setIsLoading(false);
    } else {
      toast.error("Falha ao enviar arquivo!");
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <LoadingOverlay
        show={isLoading}
        relative
      />

      <T2>Gerenciador de EDI</T2>

      <div>
        <Formik<FormValues>
          initialValues={{
            referenceDate: "",
            submitType: ""
          }}
          onSubmit={async (values, {setSubmitting, resetForm}) => {

            if (values.submitType === "download") {
              await downloadApi(values)
            } else if (values.submitType === "processar") {
              await processarApi(values)
            }
            setSubmitting(false);
            resetForm();
          }}>
          {({setFieldValue}) => {
            useEffect(() => {
              setFieldValue("referenceDate", date)
            }, [date]);
            return (
              <Form>
                <Row>
                  <Label>Data de Referencia:</Label>
                </Row>
                <FilterContainer>
                  <FilterRow>
                    <FormInput
                      type="date"
                      name="referenceDate"
                      placeholder="Data de Referencia"
                      onChange={handleDate}
                      required
                    />
                    <Button htmlType="submit" onClick={() => setFieldValue('submitType', 'download')}>
                      Download no Banco
                    </Button>
                    <Button htmlType="submit" onClick={() => setFieldValue('submitType', 'processar')}>
                      Processar tabela EDI
                    </Button>
                  </FilterRow>
                </FilterContainer>

                <Row style={{marginTop: "40px"}}>
                  <T2>Arquivo de Depósito</T2>
                </Row>
                <FilterContainer>
                  <FilterRow>
                    <Row>
                      <Col>
                        <input
                          type="file"
                          onChange={handleFileSelect}
                          accept=".ret, .txt, .rem"
                        />
                      </Col>
                      <Col style={{marginLeft: "25px"}}>
                        <Button
                          onClick={handleUpload}
                          disabled={!selectedFile || isLoading}
                        >
                          {isLoading ? 'Enviando...' : 'Enviar Arquivo'}
                        </Button>
                      </Col>

                    </Row>
                  </FilterRow>
                </FilterContainer>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Container>
  );
};

export default GerenciadorEdi;
