import {IFilter} from "../../types";
import {padding} from "polished";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import React, {useEffect, useState} from "react";
import {Row} from "antd";
import {Container} from "../../styles";
import {Field, Form, Formik} from "formik";
import {formatDateFilter} from "../../../../adiqEdi/detalhesEdi/utils";
import useGetMe from "../../../../../hooks/useCurrentUser";
import Select from "antd/lib/select";
import {Button, Col, Label} from "./styles";


export const FormFilter: React.FC<IFilter> = (props: IFilter) => {
  const {getFilters} = props
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const user = useGetMe();

  const handleChangeInitialDate = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const {value} = e.target;
    const date = new Date(value + "T03:00:00Z");
    setFieldValue("startDate", date);
  };

  const handleChangeFinalDate = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const {value} = e.target;
    const date = new Date(value + "T02:59:59Z");
    date.setDate(date.getDate() + 1);
    setFieldValue("finishDate", date);
  };

  const getDeposits = async (submit: any) => {
    if (submit.startDate === "") {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      submit.startDate = formatDateFilter(date)
    } else {
      const formattedDateStart = formatDateFilter(submit.startDate)
      submit.startDate = formattedDateStart
    }

    if (submit.finishDate === "") {
      const tomorrow = new Date()
      tomorrow.setHours(23, 59, 59, 0)
      submit.finishDate = formatDateFilter(tomorrow)
    } else {
      const formattedDateFinish = formatDateFilter(submit.finishDate)
      submit.finishDate = formattedDateFinish
    }

    getFilters(submit);
    return submit;
  }

  useEffect(() => {
    const initialValues = {
      startDate: "",
      finishDate: "",
      merchantId: "",
      valoriBank: "",
      receivingBank: "",
      transactionCode: "",
      fileIdentifier: "",
      id: "",
    }

    getDeposits(initialValues);
  }, []);

  return (
    <Container style={{padding: "0px"}}>
      <LoadingOverlay show={isLoading} relative/>

      <Row>
        <Label>Filtrar por:</Label>
      </Row>

      <Formik initialValues={{
        startDate: "",
        finishDate: "",
        merchantId: "",
        valoriBank: "",
        receivingBank: "",
        transactionCode: "",
        fileIdentifier: "",
        id: "",
      }}
              onSubmit={async (values: any) => {
                await getDeposits(values);
              }}
      >
        {({setFieldValue, values}) => (

          <Form>
            <Row gutter={16}>
              <Col>
                <input
                  type="date"
                  onChange={(e) => handleChangeInitialDate(e, setFieldValue)}
                  name="startDate"
                  placeholder="Data Inicial"
                  step="1"
                />
              </Col>
              <Col>
                <input
                  type="date"
                  onChange={(e) => handleChangeFinalDate(e, setFieldValue)}
                  name="finishDate"
                  placeholder="Data Final"
                  step="1"
                />
              </Col>

              {(user.userType === 3 || user.userType === 6 || user.userType === 7) && (
                <Col>
                  <Field
                    type="text"
                    name="merchantId"
                    placeholder="Cód. Lojista"
                  />
                </Col>
              )}

              {(user.userType === 3) && (
                <>
                  <Col>
                    <Select
                      value={values.valoriBank}
                      style={{width: "100%"}}
                      onChange={(value) => setFieldValue("valoriBank", value)}
                      options={[
                        {value: "", label: "Banco Depositado"},
                        {value: "341", label: "Itaú"},
                        {value: "274", label: "BMP"},
                        {value: "218", label: "BS2"},
                      ]}
                    />
                  </Col>

                  <Col>
                    <Field
                      type="text"
                      name="transactionCode"
                      placeholder="Código do Depósito"
                    />
                  </Col>

                  <Col>
                    <Field
                      type="text"
                      name="fileIdentifier"
                      placeholder="Nome do Arquivo"
                    />
                  </Col>

                </>
              )}
              <Col>
                <Button
                  style={{background: "#fbb651"}}
                  htmlType="submit"
                >
                  Consultar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
