import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  loadingModal: false,
  isViewUpdateUserModalOpen: false,
  isViewUserModalOpen: false,
  isViewCreateUserModalOpen: false,
  user: {
    id: '',
    name: '',
    lastName: '',
    email: '',
    documentNumber: '',
    phoneNumber: '',
    createdAt: new Date(),
    birthDate: new Date(),
    userType: 1,
    travaAntecipadora: false,
    commercialHierId: 1,
  },
  users: {
    count: 0,
    offset: 0,
    records: [],
    pageSize: 20,
  },
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.LOADING_STATUS_PAGE:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.LOADING_STATUS_MODAL:
      return {
        ...state,
        loadingModal: action.payload?.status ?? false,
      };
    case ActionTypes.GET_ALL_USERS:
      return {
        ...state,
        loading: false,
        users: action.payload?.users ?? { ...InitialState.users },
      };
    case ActionTypes.OPEN_CREATE_USER_MODAL:
      return {
        ...state,
        isViewCreateUserModalOpen: true,
      };
    case ActionTypes.UPDATE_USER_MODAL_STATUS:
      return {
        ...state,
        isViewUpdateUserModalOpen: action.payload.status ?? false,
      };
    case ActionTypes.CREATE_NEW_USER:
      return {
        ...state,
        loadingModal: false,
        isViewCreateUserModalOpen: false,
      }
    case ActionTypes.UPDATE_USER_MODAL:
      return {
        ...state,
        user: action.payload?.user ?? { ...InitialState.user },
      }
    case ActionTypes.VIEW_USER_MODAL_STATUS:
      return {
        ...state,
        isViewUserModalOpen: action.payload.status ?? false,
      };
    default:
      return { ...state };
  }
};
