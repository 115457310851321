export const formatMoney = (value: number): string => {
  const formattedValue: string = (value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formattedValue;
};

export const formatDateView = (dateString: Date | string): string => {
  const date: Date = new Date(dateString);

  // Pad single digits with leading zero
  // @ts-ignore
  const pad = (num) => (num < 10 ? `0${num}` : num);

  const formattedDate: string = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()} ${pad(date.getHours())}:${pad(date.getUTCMinutes())}:${pad(date.getUTCSeconds())}`;

  return formattedDate;
};

export const formatStatus = (status: string | number): string => {
  let statusString: string = '';

  if (status === 0) {
    statusString = 'Pendente';
  } else if (status === 1) {
    statusString = 'Confirmada';
  } else if (status === 2) {
    statusString = 'Desfeita';
  } else if (status === 3) {
    statusString = 'Negada';
  } else if (status === 4) {
    statusString = 'Cancelada';
  } else if (status === -1) {
    statusString = 'Cancelada posterior';
  } else if (status === -2) {
    statusString = 'Cancelada parcialmente'
  }

  return statusString;
};

export const formatAcquirer = (acquirer: any): string => {
  let acquirerStr: string = '';

  if (acquirer === 3) {
    acquirerStr = 'Adiq';
  } else if (acquirer === 10) {
    acquirerStr = 'Rede';
  } else if (acquirer === 99) {
    acquirerStr = 'Valori';
  }
  return acquirerStr;
};

export const formatCommercialHierarchy = (code: string | number): string => {
  let commercial: string;
  const codeNumber: number = typeof code === 'string' ? parseInt(code): code;

  switch (codeNumber) {
    case 196: commercial = 'Renan - RBE';
      break;
    case 197: commercial = 'Alfredo';
      break;
    case 199: commercial = 'Cleyton';
      break;
    case 200: commercial = 'João Gouveia';
      break;
    case 201: commercial = 'Vanessa Vasconcelos';
      break;
    case 202: commercial = 'Livia Arriel';
      break;
    case 203: commercial = 'Gustavo Braz';
      break;
    case 204: commercial = 'Evelyn Christina';
      break;
    case 205: commercial = 'Paulo Henrique';
      break;
    case 206: commercial = 'Executivo 7';
      break;
    case 207: commercial = 'Executivo 8';
      break;
    case 208: commercial = 'Josué';
      break;
    case 209: commercial = 'Matheus Lima';
      break;
    case 211: commercial = 'Yuzer';
      break;
    case 212: commercial = 'Avizz';
      break;
    case 213: commercial = 'Maikon';
      break;
    case 214: commercial = 'Danielly';
      break;
    case 215: commercial = 'T-PAY';
      break;
    case 216: commercial = 'Rodrigo Rodrigues';
      break;
    case 217: commercial = 'Avanth';
      break;
    case 218: commercial = 'WL Simpbank';
      break;
    case 219: commercial = 'B10 Bank';
      break;
    case 220: commercial = 'Conecta On Pay';
      break;
    case 221: commercial = 'Versatil Soluções';
      break;
    case 222: commercial = 'K&V Soluções';
      break;
    case 223: commercial = 'Muyto';
      break;
    case 224: commercial = 'C9 PAY';
      break;
    case 225: commercial = 'Plus Bank';
      break;
    default: commercial = '';
  }

  return commercial;
}

export const formatChannel = (channel: string): string => {
  if (channel === 'PHYSICAL') return 'Físico';
  else if (channel === 'DIGITAL') return 'Digital';
  else return '';
}

export const clearTokens = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userInfo');
}
