import {LiveTableProps} from "../../types";
import {ResponsiveColumn, ResponsiveRow, TableContainer} from "../../styles";
import Table, { TableColumn } from "../../../../components/Table";
import React, { useEffect, useState } from "react";

import {formatAcquirer, formatDate, formatModel} from "../../utils";
import useGetMe from "../../../../hooks/useCurrentUser";

export const LiveTable: React.FC<LiveTableProps> = ({
  data,
  currentPage,
  pageSize,
  onPageChange,
  onPageSizeChange
}) => {

  const user = useGetMe();


  return (
    <div>
      <TableContainer>
        <Table
          dataSource={data.records}
          pagination={{
            defaultPageSize: pageSize,
            defaultCurrent: currentPage,
            current: currentPage,
            onShowSizeChange: (_, size: number) => {
              onPageSizeChange(size);
            },
            total: data.count,
            onChange: (pageNum) => onPageChange(pageNum),
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} de ${total} Terminais`,
          }}
        >
          {(user.userType === 3 || user.userType === 6 ) && (
            <>
              <TableColumn title="Cód. Lojista" dataIndex="merchantId" key="merchantId" />
            </>
          )}
          <TableColumn title="Terminal" dataIndex="terminal" key="terminal" />
          {(user.userType === 3 || user.userType === 6) && (
            <>
              <TableColumn title="Status" dataIndex="status" key="status" />
            </>
          )}
          <TableColumn title="Serial Number" dataIndex="serialNumber" key="serialNumber" />
          <TableColumn title="TID" dataIndex="tefTerminal" key="tefTerminal" />
          <TableColumn title="Modelo" dataIndex="modelName" key="modelName" render={formatModel}/>
          <TableColumn title="Data Da Ultima Transação" dataIndex="lastTransactionDate" key="lastTransactionDate" render={formatDate}/>
          {(user.userType === 3) && (
            <TableColumn
                title="Adquirente"
                key="tefAcquirer"
                render={(text, record: any) =>
                  formatAcquirer(
                    record.primaryTefAcquirer,
                    record.primaryAcquirerPriority,
                    record.secondaryTefAcquirer,
                    record.secondaryAcquirerPriority
                  )}/>
          )}
          {(user.userType === 3 || user.userType === 6) && (
            <>
              <TableColumn title="Data Da Criação" dataIndex="createdAt" key="createdAt" render={formatDate}/>
              <TableColumn title="Data Da Ultima Atualização" dataIndex="updatedAt" key="updatedAt" render={formatDate} />
              <TableColumn title="Data Da Ultima Associação" dataIndex="lastEquipmentAssociationDate" key="lastEquipmentAssociationDate" render={formatDate}/>
            </>
          )}
        </Table>
      </TableContainer>

      {/*<ResponsiveRow style={{ borderBottom: '1px solid gray', borderRadius: '0px'}}>*/}
      {/*  <ResponsiveColumn style={{width: "33%"}}><h4>Merchant</h4></ResponsiveColumn>*/}
      {/*  <ResponsiveColumn style={{width: "33%"}}><h4>Terminal</h4></ResponsiveColumn>*/}
      {/*  <ResponsiveColumn style={{width: "33%"}}><h4>Serial Number</h4></ResponsiveColumn>*/}
      {/*</ResponsiveRow>*/}
      {/*{terminals.map((terminal: any) =>{*/}
      {/*  return (*/}
      {/*    <ResponsiveRow style={{backgroundColor: "white", boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.2)"}}>*/}
      {/*      <ResponsiveColumn style={{width: "33%"}}>*/}
      {/*        <p>{(terminal.merchant)}</p>*/}
      {/*      </ResponsiveColumn>*/}
      {/*      <ResponsiveColumn style={{width: "33%"}}>*/}
      {/*        <p>{(terminal.terminal)}</p>*/}
      {/*      </ResponsiveColumn>*/}
      {/*      <ResponsiveColumn style={{width: "33%"}}>*/}
      {/*        <p>{(terminal.serial_number)}</p>*/}
      {/*      </ResponsiveColumn>*/}
      {/*    </ResponsiveRow>*/}
      {/*  )*/}
      {/*})}*/}
    </div>
  );
}

export default LiveTable;
