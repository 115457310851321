export const formatMoney = (value: number): string => {
  const formattedValue: string = (value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formattedValue;
};

export const formatDateHourView = (dateString: Date | string): string => {
  const date: Date = new Date(dateString);

  // Pad single digits with leading zero
  // @ts-ignore
  const pad = (num) => (num < 10 ? `0${num}` : num);

  const formattedDate: string = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()} ${pad(date.getHours())}:${pad(date.getUTCMinutes())}:${pad(date.getUTCSeconds())}`;

  return formattedDate;
};

export const formatDateView = (dateString: Date | string): string => {
  const date: Date = new Date(dateString);

  // Pad single digits with leading zero
  // @ts-ignore
  const pad = (num) => (num < 10 ? `0${num}` : num);

  const formattedDate: string = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`;

  return formattedDate;
};

export const formatDateFilter = (dateString: Date | string): string => {
  const date: Date = new Date(dateString);

  // Pad single digits with leading zero
  const pad = (num: number): string => (num < 10 ? `0${num}` : `${num}`);

  const formattedDate: string = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;

  return formattedDate;
};



export const formatTypePurchase = (value: number): string => {
  let typePurchase: string = '';

  switch (value) {
    case 1: typePurchase = 'Débito';
    break;
    case 2: typePurchase = 'Crédito'
  }

  return typePurchase;
}

export const formatBrand = (value: number): string => {
  let brand: string = '';

  switch (value) {
    case 1 : brand = 'Visa';
    break;
    case 2 : brand = 'Master';
      break;
    case 3 : brand = 'Amex';
      break;
    case 9 : brand = 'Hiper';
      break;
    case 171 : brand = 'Elo';
      break;
    default: brand = value.toString()
  }

  return brand
}

export const formatTransactionTax = (value: number): string => {
  let result = '';
  result =`${(value*100).toFixed(2).toString()}%`;
  return result;
}

export const formatDocument = (document: string): string => {
  const cleaned = document.replace(/\D/g, ''); // Remove tudo que não for número

  if (cleaned.length === 11) {
    // Formatar CPF (###.###.###-##)
    return cleaned.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else if (cleaned.length === 14) {
    // Formatar CNPJ (##.###.###/####-##)
    return cleaned.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  } else {
    // Retorna o original se não for nem CPF nem CNPJ
    return document;
  }
};
