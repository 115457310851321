import {getAxiosInstance} from "../../../../services/providers/api";
import {FormValues} from "../types";
import {getErrorMessage} from "../../../../utils/getErrorMessage";

export default class PainelDeDepositosService {
  uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await getAxiosInstance().post('/declaracao-transacao/upload-file', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      console.log(errorMessage);
    }
  }

  getDepositos = async (
    page: number,
    pageSize: number,
    filter?: FormValues
  ) => {
    const selectedCompany: string | null = localStorage.getItem("selectedCompany");
    let parsedSelectedCompany = null;
    if (selectedCompany) parsedSelectedCompany = JSON.parse(selectedCompany);

    const paged = true;
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const merchantId = parsedSelectedCompany ? parsedSelectedCompany.merchantId : filter?.merchantId !== undefined && filter?.merchantId.length > 0 ? filter?.merchantId : null;
    const valoriBank = filter?.valoriBank !== -1 ? filter?.valoriBank : null;
    const receivingBank = filter?.receivingBank !== -1 ? filter?.receivingBank : null;
    const transactionCode = filter?.transactionCode || null;
    const fileIdentifier = filter?.fileIdentifier || null;
    const id = filter?.id !== -1 ? filter?.id : null;

    try {
      const response = await getAxiosInstance().get('/declaracao-transacao/get-depositos', {
        params: {
          offSet: page,
          pageSize: pageSize,
          paged: paged,
          startDate: startDate,
          finishDate: finishDate,
          merchantId: merchantId,
          valoriBank: valoriBank,
          receivingBank: receivingBank,
          transactionCode: transactionCode,
          fileIdentifier: fileIdentifier,
          id: id,
        }
      });

      return response.data.response;
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      console.log(errorMessage);
    }
  }

  getDepositPdf = async (
    page: number,
    pageSize: number,
    id?: number
  ) => {
    const selectedCompany: string | null = localStorage.getItem("selectedCompany");
    let parsedSelectedCompany = null;
    if (selectedCompany) parsedSelectedCompany = JSON.parse(selectedCompany);

    const paged = true;

    try {
      const response = await getAxiosInstance().get('/declaracao-transacao/get-depositos-pdf', {
          params: {
            offSet: page,
            pageSize: pageSize,
            paged: paged,
            id: id,
          },
          responseType: "blob"
        }
      );
      return response.data;
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      console.log(errorMessage);
    }
  }
}
