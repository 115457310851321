import {LiveTableProps} from "../../types";
import {TableContainer} from "./styles";
import {Table} from "antd";
import {TableColumn} from "../../../../../components/Table";
import {formatDateView, formatDocument, formatMoney} from "../../../../adiqEdi/detalhesEdi/utils";
import useGetMe from "../../../../../hooks/useCurrentUser";
import {DownloadOutlined, EyeOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {id} from "date-fns/locale";
import PainelDeDepositosService from "../../service/painelDeDepositosService";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import { formatAgency } from "../../utils";

export const LiveTable: React.FC<LiveTableProps> = ({
  data,
  currentPage,
  pageSize,
  onPageChange,
  onPageSizeChange
  }) => {
  const user = useGetMe();
  const [filterData, setFilterData] = useState<any>(null);
  const formattedDate = (date: string) => formatDateView(date);
  const formattedMoney = (value: number) => formatMoney(value);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const painelDeDepositosService = new PainelDeDepositosService();
  const formattedDocument = (document: string) => formatDocument(document);
  const formattedAgency = (agency: string) => formatAgency(agency);

  const downloadPdf = async (id: number) => {
    setIsLoading(true)
    const response = await painelDeDepositosService.getDepositPdf(0, 1, id
    );

    if (response) {
      const blob = new Blob([response], { type: 'application/pdf' })

      const objectUrl = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = objectUrl;
      a.download = 'Declaração de Transação.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(objectUrl);
      document.body.removeChild(a);
    }
    setIsLoading(false)
  }

  console.log(currentPage, pageSize);

  return (
    <div>
    <LoadingOverlay
        show={isLoading}
        relative
      />
      <TableContainer>
        <Table
          dataSource={data.records}
          pagination={
            {
              defaultPageSize: pageSize,
              defaultCurrent: currentPage,
              current: currentPage,
              onShowSizeChange: (_, size) => {
                onPageSizeChange(size);
              },
              total: data.count,
              onChange: (pageNum) => onPageChange(pageNum),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} de ${total} depósitos`,
            }}
        >
          <TableColumn title="Data" dataIndex="paymentDate" key="paymentDate" render={formattedDate}/>
          <TableColumn title="Codigo do logista" dataIndex="merchantId" key="merchantId" />
          <TableColumn title="Documento" dataIndex="document" key="document" render={formattedDocument}/>
          <TableColumn title="Nome do Favorecido" dataIndex="receivingName" key="receivingName" />
          <TableColumn title="Banco" dataIndex="receivingBank" key="receivingBank"/>
          <TableColumn title="Agencia" dataIndex="receivingAgency" key="receivingAgency" render={formattedAgency}/>
          <TableColumn title="Conta" dataIndex="receivingAccount" key="receivingAccount"/>
          <TableColumn title="Valor Depositado" dataIndex="value" key="value" render={formattedMoney}/>
          <TableColumn title="Código do Depósito" dataIndex="transactionCode" key="transactionCode"/>
          {(user.userType === 3) && (
            <TableColumn title="Banco Valori" dataIndex="valoriBank" key="valoriBank"/>
          )}
          {(user.userType === 3) && (
            <TableColumn title="Agencia Valori" dataIndex="valoriAgency" key="valoriAgency" render={formattedAgency}/>
          )}
          {(user.userType === 3) && (
            <TableColumn title="Conta Valori" dataIndex="valoriAccount" key="valoriAccount"/>
          )}
          {(user.userType === 3) && (
            <TableColumn title="Arquivo" dataIndex="fileIdentifier" key="fileIdentifier"/>
          )}
          <TableColumn
            title="Download"
            key="download"
            dataIndex="download"
            render={(_: any, deposit: any) => (data.records.length >= 1 ? (
              <DownloadOutlined style={{fontSize: "20px"}} onClick={() => downloadPdf(deposit.id)} />
            ) : null)}
          />
        </Table>
      </TableContainer>
    </div>

  )
}

export default LiveTable;
