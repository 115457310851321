import React, {useEffect, useMemo, useReducer, useRef, useState} from 'react';
import Space from 'antd/lib/space';
import Button from 'antd/lib/button';
import {FilterConfirmProps} from 'antd/lib/table/interface';
import {ColumnType} from 'antd/es/table';
import {Input} from 'antd';
import {SearchOutlined} from '@ant-design/icons/lib';
import Table, {TableColumn} from '../../../../../components/Table';
import {ITransactionsTable} from '../../types';
import {createFormatProvider} from '../../../../../services/providers/factories';
import {ResponsiveColumn, ResponsiveRow, ResponsiveTable, TableContainer} from '../../styles';
import useGetMe from "../../../../../hooks/useCurrentUser";
import {getAllLiveTransactionsRecebiveis} from "../../actions";
import {formatDate} from "../../../../../utils/formatDate";
import {InitialState, Reducer} from "../../../painelDeVendas/actions/reducer";
import {
  formatAcquirer,
  formatChannel,
  formatDateView,
  formatMoney,
  formatSaleDateView,
  formatStatus
} from "../../utils";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import Select from "antd/lib/select";
import RecebiveisServices from "../../services/RecebiveisServices";
import {getErrorMessage} from "../../../../../utils/getErrorMessage";

interface DataType {
  paymentDate: string;
  tempo: string;
  empresa: string;
  document: string;
  businessName: string;
  acquirer: string;
  terminal: string;
  tefTerminal: string;
  brand: string;
  authorizationNumber: string;
  cardNumber: string;
  productName: string;
  acquirerNsu: string;
  originalValue: string;
  status:string;
  parcels: string;
  terminalSerialNumber: string;
  liquidValue: string;
  value: string;
  receiveDate: string;
  currentParcel: string;
  paymentStatus: string;
  merchant: string;
  captureChannel: string;
  orderNumber: string;
}

type DataIndex = keyof DataType;

const LiveTable: React.FC<ITransactionsTable> = (props: ITransactionsTable) => {
  const user = useGetMe();
  const recebiveisServices = new RecebiveisServices();
  const {dataFilter, getTotals } = props;
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [totalGross, setTotalGross] = useState(0);
  const [totalLiquid, setTotalLiquid] = useState(0);
  const [count, setCount] = useState(0);
  const [totalPayed, setTotalPayed] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [externalTotalGross, setExternalTotalGross] = useState(0);
  const [externalTotalLiquid, setExternalTotalLiquid] = useState(0);
  const [externalCount, setExternalCount] = useState(0);
  const [externalTotalPayed, setExternalTotalPayed] = useState(0);
  const [externalTotalPending, setExternalTotalPending] = useState(0);
  const [sortBy, setSortBy] = useState('desc');
  const [liveTransactions, setLiveTransactions] = useState<any[]>([]);
  const [externalLiveTransactionsRecords, setExternalLiveTransactionsRecords] = useState<any[]>([]);
  const [externalLiveTransactions, setExternalLiveTransactions] = useState<any>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));
  const formattedDate = (date: string) => formatDateView(date);
  const formattedSaleDate = (date: string) => formatSaleDateView(date);
  const formattedStatus = (status: string) => formatStatus(status);
  const formattedMoney = (value: number) => formatMoney(value);

  let today: Date = new Date();
  let tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  today.setHours(0, 0, 0, 0);
  tomorrow.setHours(0 ,0 ,0 ,0);

  const [dataFilterState, setDataFilterState] = useState({
    initialDate: formatDate(today),
    finalDate: formatDate(today),
    saleInitialDate: undefined,
    saleFinalDate: undefined
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex:any,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const handleSortBy = (value: string): void => {
    setSortBy(value);
  };

  const filter = (type: string) => {
    const handleAdquirenteFilter = (value: any, record: any) => {
      return record[type].toLowerCase().includes(value.toLowerCase());
    };

    return handleAdquirenteFilter;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        await recebiveisServices.getRecebiveis(
          currentPage - 1,
          pageSize,
          dataFilterState,
          sortBy
        ).then(response => {
          setExternalLiveTransactions(response.data);
          setExternalLiveTransactionsRecords(response.data.records);
        })
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        console.log(errorMessage);

        if (errorMessage === 'Request failed with status code 401') {
          window.location.assign(`${window.location.origin}/login`);
        }
      }

      try {
        await recebiveisServices.getTotais(
          dataFilterState,
          sortBy
        ).then(response => {
          if (response.data.totalGross === null) setExternalTotalGross(0);
          else setExternalTotalGross(response.data.totalGross);

          if (response.data.totalLiquid === null) setExternalTotalLiquid(0);
          else setExternalTotalLiquid(response.data.totalLiquid);

          if (response.data.totalPending === null)setExternalTotalPending(0);
          else setExternalTotalPending(response.data.totalPending);

          if (response.data.totalPayed === null)setExternalTotalPayed(0);
          else setExternalTotalPayed(response.data.totalPayed);

          if (response.data.quantidade === null) setExternalCount(0);
          else setExternalCount(response.data.quantidade);
        });

      } catch (error) {
        const errorMessage = getErrorMessage(error);
        console.log(errorMessage);

        if (errorMessage === 'Request failed with status code 401') {
          window.location.assign(`${window.location.origin}/login`);
        }
      }

      finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [dataFilterState]);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        await recebiveisServices.getRecebiveis(
          currentPage - 1,
          pageSize,
          dataFilterState,
          sortBy
        ).then(response => {
          setExternalLiveTransactions(response.data);
          setExternalLiveTransactionsRecords(response.data.records);
        })
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        console.log(errorMessage);

        if (errorMessage === 'Request failed with status code 401') {
          window.location.assign(`${window.location.origin}/login`);
        }
      }

      finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [sortBy, currentPage, pageSize]);

  useEffect(() => {
    if (dataFilter) {
      setCurrentPage(1);
      setDataFilterState(dataFilter);
    }
  }, [dataFilter]);

  useEffect(() => {
    if (externalTotalGross === null) setTotalGross(0);
    else setTotalGross(externalTotalGross);

    if (externalTotalLiquid === null) setTotalLiquid(0);
    else setTotalLiquid(externalTotalLiquid);

    if (externalCount === null) setCount(0);
    else setCount(externalCount);

    if (externalTotalPending === null) setTotalPending(0);
    setTotalPending(externalTotalPending);

    if (externalTotalPayed === null) setTotalPayed(0);
    setTotalPayed(externalTotalPayed);
  }, [externalCount, externalTotalGross]);

  useEffect(() => {
    if(getTotals) {
      getTotals({
        totalGross: totalGross,
        totalLiquid: totalLiquid,
        totalPending: totalPending,
        totalPayed: totalPayed,
        count: count
      })
    }
  }, [totalGross, totalLiquid, count, totalPending, totalPayed]);

  useEffect(() => {
    const formattedExternalLiveTransactionsRecords = externalLiveTransactionsRecords.map((recebivel: any) => {
      const date = new Date(recebivel.receiveDate);

      let userTimezoneOffset = date.getTimezoneOffset() * 60000;
      const formattedDate = new Date(date.getTime() + userTimezoneOffset);

      return {
        ...recebivel,
        receiveDate: formattedDate
      }
    });

    setLiveTransactions(formattedExternalLiveTransactionsRecords);
  }, [externalLiveTransactionsRecords]);


  return (
    <div>
      <LoadingOverlay show={isLoading} />
      <Select
        defaultValue="Ordenar"
        style={{width: 200, marginBottom: 20}}
        onChange={handleSortBy}
        options={[
          {
            value: 'asc',
            label: 'Mais antigo',
          },
          {
            value: 'desc',
            label: 'Mais recente',
          },
        ]}
      />
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <TableContainer>
        <Table
          dataSource={liveTransactions}
          pagination={
            {
              defaultPageSize: 20,
              defaultCurrent: 0,
              current: currentPage,
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
                setCurrentPage(current);
              },
              total: externalCount,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
              showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} recebíveis`
            }
          }
        >
          <TableColumn title="Data de recebimento" dataIndex="receiveDate" key="receiveDate" render={formattedDate} />
          <TableColumn title="Data/Hora da venda" dataIndex="paymentDate" key="paymentDate" render={formattedSaleDate} onFilter={filter('paymentDate')} />
          <TableColumn title="CNPJ/CPF" dataIndex="document" key="document" />
          {(user.userType === 3  || user.userType === 6 || user.userType === 7) && (
            <TableColumn title="Empresa" dataIndex="businessName" key="businessName" onFilter={filter('businessName')} />
          )}
          {(user.userType === 3 || user.userType === 6 || user.userType === 7) && (
          <TableColumn title="Codigo do lojista" dataIndex="merchant" key="merchant" onFilter={filter('merchant')} />
            )}
          <TableColumn title="NSU" dataIndex="acquirerNsu" key="acquirerNsu" onFilter={filter('acquirerNsu')} />
          <TableColumn title="Cod. Autorização" dataIndex="authorizationNumber" key="authorizationNumber" />
          <TableColumn title="Id do Pedido" dataIndex="orderNumber" key="orderNumber" />
          <TableColumn title="Terminal" dataIndex="tefTerminal" key="tefTerminal" />
          <TableColumn title="Canal do pagamento" render={formatChannel} dataIndex="captureChannel" key="captureChannel" />
          <TableColumn title="Tipo Pagamento" dataIndex="productName" key="productName" onFilter={filter('productName')} />
          <TableColumn title="Total Parcelas" dataIndex="parcels" key="parcels" onFilter={filter('parcels')}  />
          <TableColumn title="Parcela Atual" dataIndex="currentParcel" key="currentParcel" onFilter={filter('currentParcel')} />
          <TableColumn title="Bandeira" dataIndex="brand" key="brand" onFilter={filter('brand')}  />
          <TableColumn title="Valor Da Venda" render={formatMoney} dataIndex="value" key="value" onFilter={filter('value')} />
          <TableColumn title="Valor Líquido" render={formatMoney} dataIndex="liquidValue" key="liquidValue" onFilter={filter('liquidValue')} />
          {user.userType === 3 && (
            <TableColumn title="Adquirente" render={formatAcquirer} dataIndex="acquirer" key="acquirer" onFilter={filter('acquirer')} />
          )}
          <TableColumn title="Status" render={formatStatus} dataIndex="paymentStatus" key="paymentStatus" onFilter={filter('paymentStatus')} />
        </Table>
      </TableContainer>
      <ResponsiveTable>
        <ResponsiveRow style={{ borderBottom: '1px  solid gray', borderRadius: '0px'}}>
          <ResponsiveColumn><h4>Datas</h4></ResponsiveColumn>
          <ResponsiveColumn><h4>Parcelas e Pagamento</h4></ResponsiveColumn>
          <ResponsiveColumn><h4>Valores</h4></ResponsiveColumn>
        </ResponsiveRow>

        {liveTransactions.map((payment) => {
          return (
            <ResponsiveRow style={{backgroundColor: "white", boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.2)"}}>
              <ResponsiveColumn style={{maxWidth: "92px"}}>
                <p>Recebimento: {formatDateView(payment.receiveDate)}</p>
                <p>Venda: <br /> {formatDateView(payment.paymentDate)}</p>
              </ResponsiveColumn>
              <ResponsiveColumn>
                <p>{payment.productName} {payment.parcels}x</p>
                <p>Parcela atual: {payment.currentParcel}</p>
                <p style={formatStatus(payment.paymentStatus) === 'Pago' ? {color: 'green', fontWeight: 'bold'} : {color: 'red', fontWeight: 'bold'}}>{formatStatus(payment.paymentStatus)}</p>
              </ResponsiveColumn>
              <ResponsiveColumn style={{maxWidth: "88px", paddingRight: '4px'}}>
                <p>Venda: {formatMoney(payment.value)}</p>
                <p><b>Líquido: {formatMoney(payment.liquidValue)}</b></p>
              </ResponsiveColumn>
            </ResponsiveRow>
          )
        })}
      </ResponsiveTable>
    </div>
  )
}

export default LiveTable;
